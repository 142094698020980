//Imports needed for the component
import React, {useEffect, useRef} from 'react'; // Importing the necessary modules
import "../Styles/NavbarTop.css"; // Importing CSS styles for the NavbarTop component
import '../Styles/AdminVideos.css'; // Importing CSS styles for the AdminVideos component
import {useState,useCallback} from "react"; // Importing useState hook from React
import { // Importing specific components
    NavbarLeftVirtualLibraryComponent
} from "./NavbarLeftVirtualLibraryComponent";
import IMG_Example_Profile_Stiven from "../Statics/Img/stiven.jpg";
import IMG_Example_Profile_Ander from "../Statics/Img/Ander.jpg";

// Function for sending Apis request

const Send_Apis_request = async (Url, Data, Method) => { // Function for sending update request
    let Response_Get; // Variable for storing response
    let Response_Post; // Variable for storing response
    switch (Method) {
        case  'POST':
            try {
                Response_Post = await fetch(Url, { // Sending POST request
                    method: 'POST',
                    body: JSON.stringify(Data), // Converting data to JSON format
                    headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Post.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }

            } catch
                (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Post;
        case 'GET':
            try {
                Response_Get = await fetch(Url, { // Sending POST request
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Get.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }
            } catch
                (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Get;
        default:
            console.log("Method not found");
            break;
    }
};


// AdminVideos component
const AdminVideos = () => {






    //----------------------------------useState----------------------------------

    // State variables for managing the component state
    const [Global_Id_library, setGlobal_Id_library] = useState(0); // State variable for storing the global ID of the library
    const [Global_Description_Video, setGlobal_Description_Video] = useState(""); // State variable
    // for storing the global description of the video
    const [Global_Cod_process, setGlobal_Cod_process] = useState(""); // State variable
    // for storing the global code process
    const [Global_URL_process, setGlobal_URL_process] = useState(""); // State variable
    // for storing the global URL process
    // State variable
    const [Global_Installation_Inspection, setGlobal_Installation_Inspection] = useState("");

    const [Global_Detail_Description, setGlobal_Detail_Description] = useState(""); // State variable

    const [Category, setCategory] = useState([]); // State variable for storing categories
    // for storing the global installation inspection
    const [EditActive, SetEditActive] = useState(false) // State variable for managing edit mode activation








    //----------------------------------useRef----------------------------------




    const Description_Update_Ref = useRef(null); // Ref for the description input field in update mode
    const CodeProcess_Update_Ref = useRef(null); // Ref for the code process input field in update mode
    const UrlVideo_Update_Ref = useRef(null); // Ref for the URL video input field in update mode
    const InstallationInspection_Update_Ref = useRef(null); // Ref for the installation inspection input field in update mode
    const Category_Video = useRef(null); // Ref for the category video input field in update mode
    const Detail_Description_Ref = useRef(null); // Ref for the description video input field in update mode
    const [AddActive, SetAddActive] = useState(false) // State variable for managing adds mode activation
    const [libraries, setLibraries] = useState([]); // State variable for storing libraries data
    const [searchParam, setSearchParam] = useState(""); // State variable for storing search parameter
    const Description_Add_Ref = useRef(null); // Ref for the description input field in added mode
    const CodProcess_Add_Ref = useRef(null); // Ref for the code process input field in added mode
    const Url_Add_Ref = useRef(null); // Ref for the URL input field in added mode
    const Inspection_Add_Ref = useRef(null); // Ref for the inspection input field in added mode




    //----------------------------------Functions----------------------------------
    const HandleEditActive = async (Id_library, Description_Video, Cod_process, URL_process, Installation_Inspection, Detail_description) => { // Function for handling edit mode activation
        await setGlobal_Id_library(Id_library); // Set global ID library state
        await setGlobal_Description_Video(Description_Video); // Set global description video state
        await setGlobal_Cod_process(Cod_process); // Set global code process state
        await setGlobal_URL_process(URL_process); // Set global URL process state
        await setGlobal_Installation_Inspection(Installation_Inspection); // Set global installation inspection state
        await setGlobal_Detail_Description(Detail_description); // Set global detail description state
        SetEditActive(!EditActive); // Toggle edit mode state
    }

    const handleDescriptionChange = (e) => { // Function for handling description change
        setGlobal_Description_Video(e.target.value); // Update global description video state
    };

    const handleCodeProcessChange = (e) => { // Function for handling code process change
        setGlobal_Cod_process(e.target.value); // Update global code process state
    };

    const handleUrlVideoChange = (e) => { // Function for handling URL video change
        setGlobal_URL_process(e.target.value); // Update global URL process state
    };

    const handleInstallationInspectionChange = (e) => { // Function for handling installation inspection change
        setGlobal_Installation_Inspection(e.target.value); // Update global installation inspection state
    };

    const HandleAddActive = () => { // Function to toggle add mode
        SetAddActive(!AddActive); // Toggle add mode state
    }

    const handleSearchChange = (e) => { // Function to handle search input change
        setSearchParam(e.target.value); // Update search parameter state
    }

    const clearLibraries = () => { // Function to clear libraries state
        setLibraries([]); // Clear libraries state
    };

    // Función para manejar cambios en el texto del textarea
    const handleTextareaChange = (e) => {
        setGlobal_Detail_Description(e.target.value); // Actualiza el estado con el nuevo valor del textarea
    };



    //----------------------------------Functions Api----------------------------------
    const HandleSendUpdateVideo = async (Id_library) => { // Function for handling video update submission
        let response;
        const Data = { // Prepare data object for update
            "Id_library": Id_library,
            "Description_Video": Description_Update_Ref.current.value,
            "Cod_process": CodeProcess_Update_Ref.current.value,
            "URL_process": UrlVideo_Update_Ref.current.value,
            "Installation_Inspection": InstallationInspection_Update_Ref.current.value,
            "Detail_description": Detail_Description_Ref.current.value
        };
        try {
            let Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiGetLibrary.php?op=Update_Library'; // URL for updating video data
            await HandleEditActive(); // Activate edit mode
            response = await Send_Apis_request(
                Url, // URL for updating video data
                Data,
                'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al enviable la actualization del video:', error); // Log error if update fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }

    const HandleAddSubmit = async (event) => { // Function for handling video addition submission
        let response;
        event.preventDefault(); // Prevent default form submission behavior
        const Data = { // Prepare data object for addition
            "Description_Video": Description_Add_Ref.current.value,
            "Cod_process": CodProcess_Add_Ref.current.value,
            "URL_process": Url_Add_Ref.current.value,
            "Installation_Inspection": Inspection_Add_Ref.current.value,
            "Id_category_videos": Category_Video.current.value,
            "Detail_description": Detail_Description_Ref.current.value
        };
        try {
            let Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiGetLibrary.php?op=Insert_Library'; // URL for updating video data
            await HandleAddActive(); // Activate add mode
             response = await Send_Apis_request(
                Url, // URL for insert video data
                Data,
                'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al agregar el video:', error); // Log error if addition fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }


    let fetchData;
    fetchData = useCallback(async (Description_Video) => {
            try {
                let response;
                let Url;
                if (Description_Video !== "") {
                    Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiGetLibrary.php?op=Search_Video_Library'; // URL for fetching all categories
                    response = await Send_Apis_request(Url, {Description_Video}, 'POST'); // Send request to server
                } else {
                    Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiGetLibrary.php?op=Get_All_Library'; // URL for fetching all categories
                    response = await Send_Apis_request(Url, {}, 'GET'); // Send request to server
                }

                const data = await response.json();

                let librariesData;

                if (Array.isArray(data.items)) {
                    librariesData = data.items;
                } else if (data.items) {
                    librariesData = [data.items];
                } else {
                    librariesData = [];
                }

                setLibraries(librariesData);

                console.log(librariesData);

                // Check if there are more pages to fetch
                if (data.nextPageToken) {
                    // Recursively fetch more data
                    await fetchData(Description_Video);
                }
            } catch (error) {
                console.error('Error fetching libraries:', error);
            }
        },
        [fetchData]);


    const FetchAllCategory = async () => { // Function to fetch all categories
        try {
            let Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiGetLibrary.php?op=Get_All_Library_Category'; // URL for fetching all categories
            const response = await Send_Apis_request(Url, {}, 'GET'); // Send request to server
            const data = await response.json(); // Parse response data
            setCategory(data.items); // Update categories state with fetched data
        } catch (error) {
            console.error('Error fetching categories:', error); // Log error if fetching fails
        }
    }


    const HandleActiveSwitch = async (Id_library, Active) => { // Function for handling the switch activation
        try {
            let Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiGetLibrary.php?op=Update_Status_Library'; // URL for updating video data
            const Data = { // Prepare data object for update
                "Id_library": Id_library,
                "Active": Active
            };
            const response = await Send_Apis_request( // Send update request to server
                Url, // URL for updating video data
                Data,
                'POST');
            if (!response.ok) { // Handling non-ok response
                throw new Error('Failed to update video'); // Throwing error
            }
            // Updating local state if server request was successful
            const updatedLibraries = libraries.map(library => { // Mapping through libraries
                if (library.Id_Library === Id_library) { // Checking for matching library ID
                    return {...library, Active: Active ? 1 : 0}; // Updating active status
                }
                return library; // Returning an unchanged library if ID doesn't match
            });
            setLibraries(updatedLibraries); // Setting updated libraries state
        } catch (error) { // Catching errors
            console.error('Error updating switch state:', error); // Logging error
        }
    };




    //----------------------------------useEffect----------------------------------


   useEffect(() => {
    clearLibraries();
    FetchAllCategory();
    fetchData(searchParam);
}, [searchParam, fetchData]); // Agregado fetchData como una dependencia




    //----------------------------------Return----------------------------------
    return (
        <div id="Navbar_Admin_Body">
            <NavbarLeftVirtualLibraryComponent/>
            {/*Navbar AdminVideos Top*/}
            <nav id="Navbar_Admin_Top">
                <button
                    onClick={HandleAddActive}
                    title="Add New"
                    className="custom-button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60px"
                        height="60px"
                        viewBox="0 0 24 24"
                        className="custom-svg"
                    >
                        <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            strokeWidth="1.5"
                        ></path>
                        <path d="M8 12H16" strokeWidth="1.5"></path>
                        <path d="M12 16V8" strokeWidth="1.5"></path>
                    </svg>
                </button>
                <form id="Form_Navbar_Top_Admin_Search">
                    <label id="Label_Navbar_Top_Admin_Search" htmlFor="search">
                        <input
                            id="Input_Navbar_Top_Admin_Search"
                            type="text"
                            required=""
                            placeholder="Search video"
                            className="search"
                            value={searchParam}
                            onChange={handleSearchChange}
                        />
                        <div id="Fancy_BG_Navbar_Top_Admin_Search"></div>
                        <div id="Search_Navbar_Top_Admin_Search">
                            <svg viewBox="0 0 24 24" aria-hidden="true" id="SVG_Navbar_Top_Admin_Search"
                                 className=" r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
                                <g>
                                    <path
                                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                                </g>
                            </svg>
                        </div>
                        <button className="close-btn" onClick={() => setSearchParam('')}>
                            <svg xmlns="http://www.w3.org/2000/svg" id="SVG_Navbar_Top_Admin_Search"
                                 className=" h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </label>
                </form>


                <section id="Section_Navbar_Top_Admin_Widgets">
                    <div id="Div_Navbar_Top_Admin_Widgets_Search">

                    </div>
                    <div id="Div_Navbar_Top_Admin_Widgets_Notifications">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                             className="bi bi-bell-fill" viewBox="0 0 16 16">
                            <path
                                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
                        </svg>
                    </div>
                    <div id="Div_Navbar_Top_Admin_Widgets_Messages">

                        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                             className="bi bi-chat-left-text-fill" viewBox="0 0 16 16">
                            <path
                                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
                        </svg>
                    </div>

                </section>
                <section id="Section_Navbar_Top_Admin_Profile">
                    <div id="Div_Navbar_Top_Admin_Profile_Info">
                        <div id="Div_Navbar_Top_Admin_Profile_Name">
                            <p style={{
                                fontFamily: 'cursive, serif',
                                fontSize: '1.5rem',
                                padding: '0',
                                margin: '0'
                            }}>{localStorage.getItem('Complete_Name')}</p>
                        </div>
                        <div id="Div_Navbar_Top_Admin_Profile_Type_Employee">
                            <p style={{fontFamily: 'inherit'}}>Admin</p>
                        </div>
                    </div>
                    {localStorage.getItem('Complete_Name') === "Stiven Mazo" ?
                        <div id="Div_Navbar_Top_Admin_Profile_Icon"
                             style={{background: `url(${IMG_Example_Profile_Stiven}) no-repeat center center / contain`}}></div>
                        : <div id="Div_Navbar_Top_Admin_Profile_Icon"
                               style={{background: `url(${IMG_Example_Profile_Ander}) no-repeat center center / contain`}}></div>}
                </section>
            </nav>

            {/*Div Content Right*/}
            <div id="Div_Content_Right_Admin_Videos">
                {/*<section id="Section_Filters_Admin">*/}
                {/*    <div className="dropdown">*/}
                {/*        <button id="Buttons_Filter_Admin" className="btn btn-secondary dropdown-toggle" type="button"*/}
                {/*                data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*            Category*/}
                {/*        </button>*/}
                {/*        <ul className="dropdown-menu">*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Another action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Something else here</a></li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="dropdown">*/}
                {/*        <button id="Buttons_Filter_Admin" className=" btn btn-secondary dropdown-toggle" type="button"*/}
                {/*                data-bs-toggle="dropdown"*/}
                {/*                aria-expanded="false">*/}
                {/*                Date Posted*/}
                {/*        </button>*/}
                {/*        <ul className="dropdown-menu">*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Another action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Something else here</a></li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="dropdown">*/}
                {/*        <button id="Buttons_Filter_Admin" className="btn btn-secondary dropdown-toggle" type="button"*/}
                {/*                data-bs-toggle="dropdown"*/}
                {/*                aria-expanded="false">*/}
                {/*            Experience Level*/}
                {/*        </button>*/}
                {/*        <ul className="dropdown-menu">*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Another action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Something else here</a></li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="dropdown">*/}
                {/*        <button id="Buttons_Filter_Admin" className="btn btn-secondary dropdown-toggle" type="button"*/}
                {/*                data-bs-toggle="dropdown"*/}
                {/*                aria-expanded="false">*/}
                {/*            Pay*/}
                {/*        </button>*/}
                {/*        <ul className="dropdown-menu">*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Another action</a></li>*/}
                {/*            <li><a className="dropdown-item" href="https://www.google.com/">Something else here</a></li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <div id="Div_Content_Right_Admin_Videos_Table">

                    <table id="Table_Admin_Videos">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Description</th>
                            <th>Code Process</th>
                            <th>Url Video</th>
                            <th>Installation Inspection</th>
                            <th>State</th>
                            <th>Switch</th>
                            <th>Edit</th>

                        </tr>
                        {libraries.map((library, index) => (
                            <tr id="TR_Admin_Videos_Virtual_Library"
                                key={`${index}-${library.Id_Library}`}>
                                <td>{library.Id_Library}</td>
                                <td>{library.Description_Video}</td>
                                <td>{library.Cod_process}</td>
                                <td><a href={library.URL_process}>{library.URL_process}</a></td>
                                <td>{library.Installation_Inspection}</td>
                                <td>{library.Active === 1 ? "On" : "Off"}</td>
                                <td>
                                    <div id="bauble_box" className="bauble_box">
                                        <input
                                            className="bauble_input"
                                            id="bauble_check1"
                                            name="bauble"
                                            type="checkbox"
                                            checked={library.Active === 1}
                                            onChange={(e) => HandleActiveSwitch(library.Id_Library, e.target.checked)}
                                        />
                                        <label id="bauble_label" className="bauble_label"
                                               htmlFor="bauble_check">Toggle</label>
                                    </div>

                                </td>
                                <td>
                                    <button id="EditBtn" onClick={() => {
                                        HandleEditActive(library.Id_Library, library.Description_Video, library.Cod_process, library.URL_process, library.Installation_Inspection, library.Detail_description)
                                    }}>
                                        <svg height="1em" viewBox="0 0 512 512">
                                            <path
                                                d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                            ></path>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </thead>
                    </table>
                </div>
            </div>


            {AddActive && <div id="Div_Edit_Body_Admin_Videos">
                <section id="Section_Edit_Admin_Videos">
                    <h1 id="H1_Section_Edit_Admin_Videos">Add Video</h1>
                    <form id="Form_Div_Edit_Admin_Videos" onSubmit={HandleAddSubmit}>
                        <div>
                            <label className="label-edit-admin" htmlFor="description">Description:</label>
                            <input ref={Description_Add_Ref} className="input-edit-admin" type="text" id="description"
                                   name="description" placeholder="Descripción del video"/>
                            <label className="label-edit-admin" htmlFor="codProcess">Cod Process:</label>
                            <input ref={CodProcess_Add_Ref} className="input-edit-admin" type="text" id="codProcess"
                                   name="codProcess" placeholder="Código del proceso"/>


                            <label className="label-edit-admin" htmlFor="url">URL:</label>
                            <input ref={Url_Add_Ref} className="input-edit-admin" type="text" id="url" name="url"
                                   placeholder="URL del video"/>
                            <label className="label-edit-admin" htmlFor="url">Installation inspection:</label>
                            <select
                                id="inspection"
                                className="input-edit-admin"
                                aria-label="Default select example"
                                defaultValue="" // Selecciona un valor en blanco para que no haya ninguna opción seleccionada por defecto
                            >
                                <option disabled selected>Select a option:</option>
                                {/* Placeholder */}
                                {/* Resto de las opciones */}
                                <option value="Installation" ref={Inspection_Add_Ref}>Installation</option>
                                <option value="Inspection" ref={Inspection_Add_Ref}>Inspection</option>
                            </select>


                            <label className="label-edit-admin" htmlFor="url">Category of video:</label>
                            <select
                                id="inspection"
                                className="input-edit-admin"
                                aria-label="Default select example"
                                ref={Category_Video} // Asigna la ref al elemento select
                                defaultValue="" // Selecciona un valor en blanco para que el placeholder sea visible inicialmente
                            >
                                <option disabled selected>Select a option:</option>
                                {Category.map((category) => (
                                    <option
                                        key={category.Id_category_videos}
                                        value={category.Id_category_videos}
                                    >
                                        {category.Desc_category_videos}
                                    </option>
                                ))}
                            </select>

                            <label className="label-edit-admin" htmlFor="url">Description of video:</label>
                            <textarea id="Id_Text_Area_Add_Video_Admi_Videos" placeholder="Your description"
                                      ref={Detail_Description_Ref}></textarea>
                        </div>
                        <div id="Div_Form_Buttons_Edit_Admin_Videos">
                            <button id="Button_Submit_Edit_Admin_Videos" type="submit">Submit</button>
                            <button id="Button_Cancel_Edit_Admin_Videos" type="button" onClick={HandleAddActive}>Cancel
                            </button>
                        </div>
                    </form>
                </section>
            </div>}
            {EditActive && (
                <div id="Div_Edit_Body_Admin_Videos">
                    <section id="Section_Edit_Admin_Videos">
                        <h1 id="H1_Section_Edit_Admin_Videos">Edit Video</h1>
                        <form id="Form_Div_Edit_Admin_Videos" onSubmit={(e) => {
                            e.preventDefault();
                            HandleSendUpdateVideo(Global_Id_library);
                        }}>
                            <div>
                                <label className="label-edit-admin" htmlFor="description">Description:</label>
                                <input
                                    ref={Description_Update_Ref}
                                    className="input-edit-admin"
                                    type="text"
                                    id="description"
                                    name="description"
                                    placeholder="HDPE Pipe installation"
                                    value={Global_Description_Video}
                                    onChange={handleDescriptionChange}
                                />
                                <label className="label-edit-admin" htmlFor="codeProcess">Code process:</label>
                                <input
                                    ref={CodeProcess_Update_Ref}
                                    className="input-edit-admin"
                                    type="text"
                                    id="codeProcess"
                                    name="codeProcess"
                                    placeholder="2325"
                                    value={Global_Cod_process}
                                    onChange={handleCodeProcessChange}
                                />
                            </div>
                            <div>
                                <label className="label-edit-admin" htmlFor="urlVideo">URL video:</label>
                                <input
                                    ref={UrlVideo_Update_Ref}
                                    className="input-edit-admin"
                                    type="text"
                                    id="urlVideo"
                                    name="urlVideo"
                                    placeholder="www.google.com"
                                    value={Global_URL_process}
                                    onChange={handleUrlVideoChange}
                                />
                                <label className="label-edit-admin" htmlFor="url">Category of video:</label>
                                <select
                                    id="inspection"
                                    className="input-edit-admin"
                                    aria-label="Default select example"
                                    ref={Category_Video} // Asigna la ref al elemento select
                                    defaultValue="" // Selecciona un valor en blanco para que el placeholder sea visible inicialmente
                                >
                                    <option disabled selected>Select a option:</option>
                                    {Category.map((category) => (
                                        <option
                                            key={category.Id_category_videos}
                                            value={category.Id_category_videos}
                                        >
                                            {category.Desc_category_videos}
                                        </option>
                                    ))}
                                </select>
                                <label className="label-edit-admin" htmlFor="installationInspection">Installation
                                    inspection:</label>
                                <select
                                    id="inspection"
                                    className="input-edit-admin"
                                    aria-label="Default select example"
                                    value={Global_Installation_Inspection}
                                    ref={InstallationInspection_Update_Ref} // Asigna la ref al elemento select
                                    onChange={handleInstallationInspectionChange} // Controlador de eventos para manejar cambios en la selección
                                >
                                    <option disabled selected>Select a option:</option>
                                    <option value="Installation">Installation</option>
                                    <option value="Inspection">Inspection</option>
                                </select>

                                <label className="label-edit-admin" htmlFor="url">Description of video:</label>
                                <textarea id="Id_Text_Area_Edit_Video_Admin_Videos" placeholder="Your description"
                                          ref={Detail_Description_Ref} value={Global_Detail_Description} onChange={handleTextareaChange}></textarea>
                            </div>
                            <div id="Div_Form_Buttons_Edit_Admin_Videos">
                                <button id="Button_Submit_Edit_Admin_Videos" type="submit">Submit</button>
                                <button id="Button_Cancel_Edit_Admin_Videos" type="button" onClick={HandleEditActive} onChange={setGlobal_Detail_Description}>Cancel
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            )}


        </div>
    );

}

export default AdminVideos; // Exporting the Admin component
