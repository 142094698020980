//Imports needed for the component
import React, {useCallback, useEffect, useRef} from 'react'; // Importing the necessary modules
import '../Styles/AdminPowerBi.css'; // Importing CSS styles for the AdminVideos component
import {useState} from "react"; // Importing useState hook from React
import { // Importing specific components
    NavbarLeftVirtualLibraryComponent
} from "./NavbarLeftVirtualLibraryComponent";
import IMG_Example_Profile_Stiven from "../Statics/Img/stiven.jpg";
import IMG_Example_Profile_Ander from "../Statics/Img/Ander.jpg";


// Function for sending Apis request

const Send_Apis_request = async (Url, Data, Method) => { // Function for sending update request
    let Response_Get; // Variable for storing response
    let Response_Post; // Variable for storing response
    switch (Method) {
        case  'POST':
            try {
                Response_Post = await fetch(Url, { // Sending POST request
                    method: 'POST',
                    body: JSON.stringify(Data), // Converting data to JSON format
                    headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Post.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }

            } catch
                (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Post;
        case 'GET':
            try {
                Response_Get = await fetch(Url, { // Sending POST request
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Get.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }
            } catch
                (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Get;
        default:
            console.log("Method not found");
            break;
    }
};


// principal function
const AdminJobs = () => {


    //----------------------------USE STATE--------------------------------
    // State variables for managing the component state--Variables de estado para gestionar el estado del componente.
    const [Global_Id_Dashboard, setGlobal_Id_Dashboard] = useState(0); // State variable for storing the global ID of the library
    const [Global_Description_Dashboard, setGlobal_Description_Dashboard] = useState(""); // State variable
    const [Global_Cod_Job, setGlobal_Cod_Job] = useState("");
    const [Global_Address, setGlobal_Address] = useState("");
    // for storing the global code process
    const [Global_URL_process, setGlobal_URL_process] = useState(""); // State variable
    // for storing the global URL process
    // State variable
    const [Global_Info_Dashboard, setGlobal_Info_Dashboard] = useState("");
    // for storing the global installation inspection
    const [EditActive, SetEditActive] = useState(false) // State variable for managing edit mode activation
    const [AddActive, SetAddActive] = useState(false) // State variable for managing adds mode activation
    const [Dashboards, setDashboards] = useState([]); // State variable for storing Dashboards data
    const [searchParam, setSearchParam] = useState(""); // State variable for storing search parameter


    //----------------------------USE REF--------------------------------
    const Description_Update_Ref = useRef(null); // Ref for the description input field in update mode
    const Url_Dashboard_Update_Ref = useRef(null); // Ref for the URL video input field in update mode
    const Info_Dashboard_Update_Ref = useRef(null); // Ref for the installation inspection input field in update mode
    const Description_Add_Dash_Ref = useRef(null); // Ref for the description input field in added mode
    const Url_Add_Ref_Dash = useRef(null); // Ref for the URL input field in added mode
    const Info_Dashboard_Add_Ref = useRef(null); // Ref for the Info of Dashboard input field in added mode
    const Description_Add_Cod_Job_Ref = useRef(null); // Ref for the description Category input field in added mode
    const Description_Add_Job_Name_Ref = useRef(null); // Ref for the description Category input field in added mode
    const Description_Add_Address_Ref = useRef(null); // Ref for the description Category input field in added mode
    const Address_Update_Ref = useRef(null); 
    const Cod_Job_Update_Ref = useRef(null); 
    const Job_Name_Update_Ref = useRef(null); // Ref for the installation inspection input field in update mode


    //----------------------------Functions--------------------------------
    
    const HandleActiveSwitch = async (Id_Dashbord, Active) => { // Function for handling the switch activation
        try {
            let Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiJobs.php?op=Update_Status_Job'; // URL for updating video data
            const Data = { // Prepare data object for update
                "id_job": Id_Dashbord,
                "Active": Active
            };
            const response = await Send_Apis_request( // Send update request to server
                Url, // URL for updating video data
                Data,
                'POST');
            if (!response.ok) { // Handling non-ok response
                throw new Error('Failed to update video'); // Throwing error
            }
            // Updating local state if server request was successful
            const updatedDashboards = Dashboards.map(Dashboard => { // Mapping through libraries
                if (Dashboard.id_job=== Id_Dashbord) { // Checking for matching Dashboard ID
                    return {...Dashboard, Active: Active ? 1 : 0}; // Updating active status
                }
                return Dashboard; // Returning an unchanged Dashboard if ID doesn't match
            });
            setDashboards(updatedDashboards); // Setting updated libraries state
        } catch (error) { // Catching errors
            console.error('Error updating switch state:', error); // Logging error
        }
    };

    // ******************Function for handling edit mode activation*******************************
    // ******************Función para manejar la activación del modo de edición*******************************
    const HandleEditActive = async (Id_Dashbord, Description_Dashboard,Cod_Job,Address) => {
        await setGlobal_Id_Dashboard(Id_Dashbord); // Set global ID library state
        await setGlobal_Description_Dashboard(Description_Dashboard); // Set global description video state
        await setGlobal_Cod_Job(Cod_Job); // Set global description video state
        await setGlobal_Address(Address); // Set global description video state
        SetEditActive(!EditActive); // Toggle edit mode state
    }

    const HandleDescriptionChange = (e) => { // Function for handling description change
        setGlobal_Description_Dashboard(e.target.value); // Update global description video state
    };


    const HandleJobNameChange = (e) => { // Function for handling description change
        setGlobal_Cod_Job(e.target.value); // Update global description video state
    };


    const HandleAddressChange = (e) => { // Function for handling description change
        setGlobal_Address(e.target.value); // Update global description video state
    };

  


    const handleUrlDashboardChange = (e) => { // Function for handling URL video change
        setGlobal_URL_process(e.target.value); // Update global URL process state
    };

    const HandleIndoDashboard = (e) => { // Function for handling installation inspection change
        setGlobal_Info_Dashboard(e.target.value); // Update global installation inspection state
    };

    const HandleSendUpdateDash = async (Id_Dashboard) => { // Function for handling video update submission
        let response;
        const Data = { // Prepare data object for update
            "id_job": Id_Dashboard,
            "Cod_job": Cod_Job_Update_Ref.current.value,
            "Job_Name": Job_Name_Update_Ref.current.value,
            "Address": Address_Update_Ref.current.value,
            
        };
        console.log(Data);
        try {
            let Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiJobs.php?op=Update_Job'; // URL for updating video data
            await HandleEditActive(); // Activate edit mode
            response = await Send_Apis_request(
                Url, // URL for updating video data
                Data,
                'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al enviable la actualization del video:', error); // Log error if update fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }


    const HandleAddCatSubmit = async (event) => { // Function for handling video addition submission
        let response;
        event.preventDefault(); // Prevent default form submission behavior
        const Data = { // Prepare data object for addition
            "Cod_job": Description_Add_Cod_Job_Ref.current.value,
            "Job_Name": Description_Add_Job_Name_Ref.current.value,
            "Address": Description_Add_Address_Ref.current.value,
            
        };
        try {
            let Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiJobs.php?op=Insert_Job'; // URL for updating video data
            await HandleAddActive(); // Activate add mode
            response = await Send_Apis_request(
                Url, // URL for insert video data
                Data,
                'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al agregar el video:', error); // Log error if addition fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }


    const HandleAddActive = () => { // Function to toggle add mode
        SetAddActive(!AddActive); // Toggle add mode state
    }

    const HandleSearchChange = (e) => { // Function to handle search input change
        setSearchParam(e.target.value); // Update search parameter state
    }

    const ClearDashboards = () => { // Function to clear Dashboards state
        setDashboards([]); // Clear Dashboards state
    };


    let fetchData;
    fetchData = useCallback(async (Description_Dashboard) => {
            try {
                let response;
                let Url;
                if (Description_Dashboard !== "") {
                    Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiJobs.php?op=Consult_Jobs'; // URL for fetching all categories
                    response = await Send_Apis_request(Url, {concat_key: Description_Dashboard}, 'POST'); // Send request to server
                } else {
                    Url = 'http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiJobs.php?op=Consult_All_Jobs'; // URL for fetching all Jobs
                    response = await Send_Apis_request(Url, {}, 'GET'); // Send request to server
                }

                const data = await response.json();

                let dashboardsData;

                if (Array.isArray(data.items)) {
                    dashboardsData = data.items;
                } else if (data.items) {
                    dashboardsData = [data.items];
                } else {
                    dashboardsData = [];
                }

                setDashboards(dashboardsData);

                console.log(dashboardsData);

                // Check if there are more pages to fetch
                if (data.nextPageToken) {
                    // Recursively fetch more data
                    await fetchData(Description_Dashboard);
                }
            } catch (error) {
                console.error('Error fetching libraries:', error);
            }
        },
        [fetchData]);

    //----------------------------USE EFFECT--------------------------------
    useEffect(() => {
        ClearDashboards();
        fetchData(searchParam);
    }, [searchParam, fetchData]);


    return (
        <div id="Navbar_Admin_Body">
            <NavbarLeftVirtualLibraryComponent/>
            <nav id="Navbar_Admin_Top">
                <button
                    onClick={HandleAddActive}
                    title="Add New"
                    className="custom-button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60px"
                        height="60px"
                        viewBox="0 0 24 24"
                        className="custom-svg"
                    >
                        <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            strokeWidth="1.5"
                        ></path>
                        <path d="M8 12H16" strokeWidth="1.5"></path>
                        <path d="M12 16V8" strokeWidth="1.5"></path>
                    </svg>
                </button>
                <form id="Form_Navbar_Top_Admin_Search">
                    <label id="Label_Navbar_Top_Admin_Search" htmlFor="search">
                        <input
                            id="Input_Navbar_Top_Admin_Search"
                            type="text"
                            required=""
                            placeholder="Search Category"
                            className="search"
                            value={searchParam}
                            onChange={HandleSearchChange}
                        />
                        <div id="Fancy_BG_Navbar_Top_Admin_Search"></div>
                        <div id="Search_Navbar_Top_Admin_Search">
                            <svg viewBox="0 0 24 24" aria-hidden="true" id="SVG_Navbar_Top_Admin_PowerBi_Search"
                                 className=" r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
                                <g>
                                    <path
                                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                                </g>
                            </svg>
                        </div>
                        <button className="close-btn" onClick={() => setSearchParam('')}>
                            <svg xmlns="http://www.w3.org/2000/svg" id="SVG_Navbar_Top_Admin_Search"
                                 className=" h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </label>
                </form>


                <section id="Section_Navbar_Top_Admin_Widgets">
                    <div id="Div_Navbar_Top_Admin_Widgets_Search">

                    </div>
                    <div id="Div_Navbar_Top_Admin_Widgets_Notifications">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                             className="bi bi-bell-fill" viewBox="0 0 16 16">
                            <path
                                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
                        </svg>
                    </div>
                    <div id="Div_Navbar_Top_Admin_Widgets_Messages">

                        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                             className="bi bi-chat-left-text-fill" viewBox="0 0 16 16">
                            <path
                                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
                        </svg>
                    </div>

                </section>
                <section id="Section_Navbar_Top_Admin_Profile">
                    <div id="Div_Navbar_Top_Admin_Profile_Info">
                        <div id="Div_Navbar_Top_Admin_Profile_Name">
                            <p style={{
                                fontFamily: 'cursive, serif',
                                fontSize: '1.5rem',
                                padding: '0',
                                margin: '0'
                            }}>{localStorage.getItem('Complete_Name')}</p>
                        </div>
                        <div id="Div_Navbar_Top_Admin_Profile_Type_Employee">
                            <p style={{fontFamily: 'inherit'}}>Admin</p>
                        </div>
                    </div>
                    {localStorage.getItem('Complete_Name') === "Stiven Mazo" ?
                        <div id="Div_Navbar_Top_Admin_Profile_Icon"
                             style={{background: `url(${IMG_Example_Profile_Stiven}) no-repeat center center / contain`}}></div>
                        : <div id="Div_Navbar_Top_Admin_Profile_Icon"
                               style={{background: `url(${IMG_Example_Profile_Ander}) no-repeat center center / contain`}}></div>}
                </section>
            </nav>
            <div id="Div_Content_Right_Admin_PowerBi">
      

                <div id="Div_Content_Right_Admin_PowerBi_Table">


                    <table id="Table_Admin_PowerBi">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Cod Job</th>
                            <th>Descriptions</th>
                            <th>Address</th>
                            <th>Status Job</th>
                            <th>State</th>
                            <th>Switch</th>
                            <th>Edit</th>

                        </tr>
                        {Dashboards.map((Dashboard, index) => (
                            <tr id="TR_Admin_PowerBi_Virtual_Library"
                                key={`${index}-${Dashboard.id_job}`}>
                                <td>{Dashboard.id_job}</td>
                                <td>{Dashboard.Cod_job}</td>
                                <td>{Dashboard.Job_Name}</td>
                                <td>{Dashboard.Address}</td>
                                <td>{Dashboard.Description_Job_Status}</td>
                                <td>{Dashboard.Active === 1 ? "On" : "Off"}</td>
                                <td>
                                    <div id="bauble_box" className="bauble_box">
                                        <input
                                            className="bauble_input"
                                            id="bauble_check1"
                                            name="bauble"
                                            type="checkbox"
                                            checked={Dashboard.Active === 1}
                                            onChange={(e) => HandleActiveSwitch(Dashboard.id_job, e.target.checked)}
                                        />
                                        <label id="bauble_label" className="bauble_label"
                                               htmlFor="bauble_check">Toggle</label>
                                    </div>

                                </td>
                                <td>
                                    <button id="EditBtn" onClick={() => {
                                        HandleEditActive(Dashboard.id_job, Dashboard.Cod_job);
                                    }}>
                                        <svg height="1em" viewBox="0 0 512 512">
                                            <path
                                                d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                            ></path>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </thead>
                    </table>
                </div>
            </div>
            {AddActive && <div id="Div_Edit_Body_Admin_PowerBi">
                <section id="Section_Edit_Admin_PowerBi">
                    <h1 id="H1_Section_Edit_Admin_PowerBi">Add Job</h1>
                    <form id="Form_Div_Edit_Admin_PowerBi" onSubmit={HandleAddCatSubmit}>
                        <div>
                            <label className="label-edit-admin" htmlFor="description">Cod Job:</label>
                            <input ref={Description_Add_Cod_Job_Ref} className="input-edit-admin" type="number"
                                   id="description"
                                   name="description" placeholder="Cod Job"/>

                        </div>


                        <div>
                            <label className="label-edit-admin" htmlFor="description">Name Job:</label>
                            <input ref={Description_Add_Job_Name_Ref} className="input-edit-admin" type="text"
                                   id="description"
                                   name="description" placeholder="Name Job"/>

                        </div>


                        <div>
                            <label className="label-edit-admin" htmlFor="description">Address:</label>


                            <textarea ref={Description_Add_Address_Ref} className="input-edit-admin" id="description" name="description" placeholder="Address">
                                

                            </textarea>
                            

                        </div>



                        
                        <div id="Div_Form_Buttons_Edit_Admin_PowerBi">
                            <button id="Button_Submit_Edit_Admin_PowerBi" type="submit">Submit</button>
                            <button id="Button_Cancel_Edit_Admin_PowerBi" type="button" onClick={HandleAddActive}>Cancel
                            </button>
                        </div>
                    </form>
                </section>
            </div>}
            {EditActive && (
                <div id="Div_Edit_Body_Admin_PowerBi">
                    <section id="Section_Edit_Admin_PowerBi">
                        <h1 id="H1_Section_Edit_Admin_PowerBi">Edit Job</h1>
                        <form id="Form_Div_Edit_Admin_PowerBi" onSubmit={(e) => {
                            e.preventDefault();
                            HandleSendUpdateDash(Global_Id_Dashboard);
                        }}>
                            <div>
                                <label className="label-edit-admin" htmlFor="description">Cod Job:</label>
                                <input
                                    ref={Cod_Job_Update_Ref}
                                    className="input-edit-admin"
                                    type="text"
                                    id="Cod_Job"
                                    name="Cod_Job"
                                    placeholder="0001"
                                    value={Global_Description_Dashboard}
                                    onChange={HandleDescriptionChange}
                                />

                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="description">Description Job:</label>
                                <input
                                    ref={Job_Name_Update_Ref}
                                    className="input-edit-admin"
                                    type="text"
                                    id="description"
                                    name="description"
                                    placeholder="Shop"
                                    value={Global_Cod_Job}
                                    onChange={HandleJobNameChange}
                                />

                            </div>


                            <div>
                                <label className="label-edit-admin" htmlFor="description">Address:</label>
                                <textarea
                                    ref={Address_Update_Ref}
                                    className="input-edit-admin"
                                    id="description"
                                    name="description"
                                    placeholder="9344 Lanham Severn Rd, Lanham, MD 20706, EE. UU."
                                    value={Global_Address}
                                    onChange={HandleAddressChange}
                                />

                            </div>
                            
                            <div id="Div_Form_Buttons_Edit_Admin_PowerBi">
                                <button id="Button_Submit_Edit_Admin_PowerBi" type="submit">Submit</button>
                                <button id="Button_Cancel_Edit_Admin_PowerBi" type="button" onClick={HandleEditActive}>Cancel
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            )}


        </div>
    );

}

export default AdminJobs;
