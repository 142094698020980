import React, {useEffect, useState} from 'react';
import '../Styles/HomeStyles.css';
import {NavbarLeftVirtualLibraryComponent} from "./NavbarLeftVirtualLibraryComponent";
import ReactPlayer from 'react-player';
import IMG_Example_Profile_Stiven from "../Statics/Img/stiven.jpg";
import IMG_Example_Profile_Ander from "../Statics/Img/Ander.jpg";

const HighlightMatchingWords = (text, searchTerm) => {
    if (!searchTerm || searchTerm.trim() === '') return text;
    const WORDS = text.split(' ');
    return WORDS.map((word, index) => {
        const isMatching = word.toLowerCase().includes(searchTerm.toLowerCase());
        return isMatching ? (
            <span key={index} style={{backgroundColor: 'yellow'}}>
                {word}{' '}
            </span>
        ) : (
            word + ' '
        );
    });
};

const Home = (props) => {
    const Access = props.Access;
    let OptionSwitchHome = localStorage.getItem('optionSwitchHome');
    const [FetchApiUrl, setFetchApiUrl] = useState('');
    const [Data, setData] = useState(null);
    const [ArraySearch, setArraySearch] = useState([]);
    const [SearchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        switch (OptionSwitchHome) {
            case "1":
                const idVideoLibrary = localStorage.getItem('idVideoLibrary');
                setFetchApiUrl('http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiGetLibrary.php?op=Search_One_Video_Library');
                setData({Id_Library: idVideoLibrary});
                break;
            case "2":
                const idDashboard = localStorage.getItem('idDashboard');
                setFetchApiUrl('http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiDashboards.php?op=Consult_One_Dashboard');
                setData({Id_Dashbord: idDashboard});
                break;
            default:
                console.log('Error');
                break;
        }
    }, [OptionSwitchHome]);

    useEffect(() => {
        const fetchData = async () => {
            if (FetchApiUrl && Data) {
                try {
                    const response = await fetch(FetchApiUrl, {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(Data)
                    });
                    const data = await response.json();
                    let librariesData = Array.isArray(data.items) ? data.items : data.items ? [data.items] : [];
                    setArraySearch(librariesData);
                } catch (error) {
                    console.error('Error fetching ArraySearch:', error);
                }
            }
        };
        fetchData();
    }, [FetchApiUrl, Data]);

    const HandleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const HandleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <div id="Body_Home_Component">
            <NavbarLeftVirtualLibraryComponent id="NavbarLeftVirtualLibraryComponent" Access={Access}/>
            {OptionSwitchHome === "2" ?
                <div className="Div-left">
                    {/* Search Input Field */}
                    <div className="search">
                        <div className="container-fluid">
                            <form className="d-flex" onSubmit={HandleSubmit} role="search">
                                <input
                                    className="form-control me-2"
                                    type="search"
                                    placeholder="Search into description"
                                    aria-label="Search"
                                    value={SearchTerm}
                                    onChange={HandleChange}
                                />
                            </form>
                        </div>
                    </div>

                    {/* Description Section */}
                    <div className="Description">
                        {/* Description Title */}
                        <h1 className="Tittle-Description">
                            {OptionSwitchHome === "1" ?
                                ArraySearch.map((library) => library.Description_Video) :
                                OptionSwitchHome === "2" ?
                                    ArraySearch.map((library) => library.Desc_Dashboard) :
                                    "Welcome to the TCCE App! "
                            }
                        </h1>
                        {/* Description Paragraph */}
                        <p className="Parraphal-Description">
                            {OptionSwitchHome === "1" ?
                                HighlightMatchingWords(ArraySearch.map((library) => library.Detail_description).join(''), SearchTerm) :
                                OptionSwitchHome === "2" ?
                                    HighlightMatchingWords(ArraySearch.map((library) => library.Info_Dashboard).join(''), SearchTerm) :
                                    "Welcome to TCCE! With over two decades of experience in civil works, we’ve built a team of seasoned professionals who specialize in constructing roads, bridges, and buildings. Our mission is to deliver unparalleled service excellence to our customers through seamless coordination in our activities, incorporating the industry’s best skilled force. Guided by a commitment to professional management practices, we strive to exceed expectations, foster innovation, and create lasting value for our clients and stakeholders. Our goal is to ensure successful projects and sustainable growth for both our clients and our company. Reach out to us today to learn more about our services and how we can assist with your next project. 👷‍♂️🏗️"
                            }
                        </p>
                    </div>
                </div>
                : null}
            <section id="Section_Video_Home">

                <div id="Div_Video_Home">
                    <div id="Div_Title_Video">
                        <section id="Section_Navbar_Top_Admin_Profile_Home">
                            <div id="Div_Navbar_Top_Admin_Profile_Info_Home">
                                <div id="Div_Navbar_Top_Admin_Profile_Name_Home">
                                    <p style={{
                                        fontFamily: 'cursive, serif',
                                        fontSize: '1.5rem',
                                        padding: '0',
                                        margin: '0'
                                    }}>
                                        {localStorage.getItem('Complete_Name')}
                                    </p>
                                </div>
                                <div id="Div_Navbar_Top_Admin_Profile_Type_Employee_Home">
                                    <p style={{fontFamily: 'inherit'}}>Admin</p>
                                </div>
                            </div>
                            {localStorage.getItem('Complete_Name') === "Stiven Mazo" ?
                                <div id="Div_Navbar_Top_Admin_Profile_Icon_Home"
                                     style={{background: ` url(${IMG_Example_Profile_Stiven}) no-repeat center center / contain`}}></div> :
                                <div id="Div_Navbar_Top_Admin_Profile_Icon"
                                     style={{background: `url(${IMG_Example_Profile_Ander}) no-repeat center center / contain`}}></div>}
                        </section>
                    </div>
                    <h1 id="Tittle_Video_Home">
                        {OptionSwitchHome === "1" ? ArraySearch.map((library) => library.Description_Video) :
                            OptionSwitchHome === "2" ? ArraySearch.map((library) => library.Desc_Dashboard) : null}
                    </h1>
                    {OptionSwitchHome === "1" ?
                        <ReactPlayer
                            url={ArraySearch.length > 0 ? ArraySearch[0].URL_process : ''}
                            width="100%"
                            height="100%"
                            controls={true}
                            className="video-rounded"
                        /> :
                        OptionSwitchHome === "2" ?
                            <iframe
                                key={ArraySearch.length > 0 ? ArraySearch[0].Id_Dashboard : ''}
                                title={ArraySearch.length > 0 ? `Dashboard - ${ArraySearch[0].Id_Dashboard}` : 'default-title'}
                                width="100%"
                                height="100%"
                                src={ArraySearch.length > 0 ? ArraySearch[0].URL_Dashboard : ''}
                                allowFullScreen
                                className="video-rounded"
                            ></iframe>
                            :
                            <ReactPlayer
                                url={"https://youtu.be/NFQDjDFqnOI"}
                                width="100%"
                                height="100%"
                                controls={true}
                                className="video-rounded"
                            />
                    }
                </div>
            </section>
        </div>
    );
};

export default Home;
