// Importing the necessary modules
import {useRef, useState} from "react"; // Importing hooks from React
import "../Styles/LoginStyles.css"; // Importing CSS file

// Importing the necessary modules
import {CarouselLoginVirtualLibrary} from "./CarouselLoginVirtualLibrary";


const Login = ({Access}, args) => {
    //------------------------------------FORM LOGIN------------------------------------//
    //----------------------------OBJECTS------------------------------------//
    // Declares a state variable 'showPwd' using the 'useState' hook,
    // initialized to 'false'. It manages the visibility of the password input field.
    const [ShowPwd, SetShowPwd] = useState(false);

    // Create a reference for user input field
    const RefUser = useRef(null);
    // Create a reference for password input field
    const RefPassword = useRef(null);

    // State for email input value
    const [Email, SetEmail] = useState('');
    // State for email validation status
    const [EmailValid, SetEmailValid] = useState(true);

    // State for loader visibility
    const [Loader, SetLoader] = useState(false);

    // State for incorrect email or password message visibility
    const [Incorrect, SetIncorrect] = useState(false);


    //----------------------------FUNCTIONS------------------------------------//

    // Function HandleLogin that sends the login data to the server
    const HandleLogin = async () => {
        HandleLoader(true);
        // Prepare login data
        const Data = {
            "Email": RefUser.current.value + "@tcce.biz", "Password": RefPassword.current.value
        };
        try {
            // Send login data to server and get response
            const FetchLogin = await fetch("https://totalcivil.com/backend.totalcivil.com/auth/controllers/controller-auth.php?option=login", {
                method: 'POST', body: JSON.stringify(Data), headers: {
                    'Content-Type': 'application/json'
                }
            });
            // Check if response is successful
            if (!FetchLogin.ok) {
                throw new Error('Failed to Connect to server');
            }

            // Convert response to JSON
            //const Connect = await FetchLogin.json();

            // Pass the connection status to the parent component
            // eslint-disable-next-line react/prop-types
            let Connect_Status = FetchLogin.connect;
            Access(FetchLogin);
            if (Connect_Status === false) {
                HandleIncorrect(true);
            }
        } catch (error) {
            console.error('Error connecting to server:', error.message);
            // Handle error appropriately, e.g., display error message to user
        } finally {
            HandleLoader(false);
        }
    }

    // Function to handle key press events
    const HandleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Call HandleLogin function when an Enter key is pressed
            HandleLogin();
        }
    };

    // Function to handle double click events
    const HandleDoubleClick = (ref) => {
        if (ref.current) {
            // Select the input field when double-clicked
            ref.current.select();
        }
    };

    // Function to handle email input change
    const HandleEmailChange = (event) => {
        // Get the new email value from the event
        const NewEmail = event.target.value;
        // Update the email state with the new value
        SetEmail(NewEmail);
        // Validate the new email value and update the email validation state
        SetEmailValid(ValidateEmail(NewEmail));
    };

    // Function to validate an email format
    const ValidateEmail = (Email) => {
        // Regular expression for email validation
        const Regex = /^([a-zA-Z]+)$/;
        // Test if the email matches the regular expression
        return Regex.test(Email);
    };

    // Function to handle loader visibility
    const HandleLoader = (Loader) => {
        SetLoader(Loader);
    }

    // Function to handle incorrect email or password message visibility
    const HandleIncorrect = (Incorrect) => {
        SetIncorrect(Incorrect);
    }
    // Function to toggle password visibility
    const TogglePasswordVisibility = () => {
        SetShowPwd(!ShowPwd);
    };

    //----------------------------RETURN COMPONENT------------------------------------//

    return (// JSX markup for a Login component
        <div id="Body_Login">
            <div id="Div_Left_Login">
                <div id="Image_Login_Logo_Total">
                    <img id="Logo_Total_Login" alt=""/>
                </div>
                {/* Placeholder for logo image */}
                <div id="Container_Div_Left_Login">
                    <div id="Form_Login">
                        <div id="Form_Group_Login" className="form-group">
                            {/* Label for email input */}
                            <label id="Label_Login" className="Label_Login"
                                   htmlFor="username">Email</label>
                            <br/>
                            {/* Email input field */}
                            <span id="Span_Input_Group" className={`input-group ${EmailValid ? '' : 'is-invalid'}`}>
                                <input
                                    type="text"
                                    className={`form-control ${EmailValid ? '' : 'is-invalid'}`}
                                    id="User_Name_Login"
                                    name="username"
                                    ref={RefUser}
                                    value={Email}
                                    onChange={HandleEmailChange}
                                    onKeyDown={HandleKeyPress}
                                    onDoubleClick={() => HandleDoubleClick(RefUser)}
                                />
                                {/* Default domain */}
                                <p id="Input_Group_Parraphal">@tcce.biz</p>
                                {/* User icon */}
                                <i id="I_Login" className="fa fa-user"
                                   aria-hidden="true"></i>
                                {/* Invalid email feedback */}
                                {!EmailValid && <div className="invalid-feedback">Email invalid</div>}
                            </span>
                        </div>
                        <div id="Span_Input_Group" className="form-group">
                            {/* Label for password input */}
                            <label id="Label_Password" className="Label_Login"
                                   htmlFor="password">Password</label>
                            <br/>
                            {/* Password input field */}
                            <span id="Span_Input_Group"  className="input-group">
                                <input
                                    type={ShowPwd ? "text" : "password"}
                                    className="form-control"
                                    id="Password_Login"
                                    name="password"
                                    ref={RefPassword}
                                    onKeyDown={HandleKeyPress}
                                    onDoubleClick={() => HandleDoubleClick(RefPassword)}
                                />
                                {/* Password visibility toggle */}
                                <div id="ShowPassword" onClick={TogglePasswordVisibility}>
                                    {ShowPwd ? <i id="I_Login" className="fa fa-eye" aria-hidden="true"></i> :
                                        <i id="I_Login" className="fa fa-eye-slash" aria-hidden="true"></i>}
                                </div>
                            </span>
                        </div>
                        <br/>
                        {/* Remember me checkbox */}
                        <div id="Form_Check" className="form-check">
                            <input className="form-check-input"
                                   type="checkbox"
                                   value=""
                                   id="defaultCheck1"/>
                            <label className="form-check-label"
                                   htmlFor="defaultCheck1">
                                Remember me
                            </label>
                        </div>
                        {/* Login button */}
                        <button
                            id="Button_Login"
                            type="submit"
                            className="btn btn-primary"
                            onClick={HandleLogin}
                        >Login
                        </button>
                        {Loader && <div className="loadingspinner">
                            <div id="square1"></div>
                            <div id="square2"></div>
                            <div id="square3"></div>
                            <div id="square4"></div>
                            <div id="square5"></div>
                        </div>}
                        {Incorrect && <h5 id="H5_Email_Or_Passwprd_Incorrect">Email or password incorrect</h5>}

                    </div>
                </div>
            </div>
            <div id="Div_Right_Login">
                {/* Carousel component */}
                <CarouselLoginVirtualLibrary/>
            </div>
        </div>);
};

export default Login;


// status: OK
