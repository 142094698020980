import React, { useEffect, useState } from 'react';
import { NavbarLeftVirtualLibraryComponent } from "./NavbarLeftVirtualLibraryComponent";
import '../Styles/VirtualDashboardVideoMenu.css';
import IMG_Example_Profile_Stiven from '../Statics/Img/stiven.jpg';
import IMG_Example_Profile_Ander from '../Statics/Img/Ander.jpg';
import IMG_Dash_1 from '../Statics/Img/PowerBi/Dash1.png';
import IMG_Dash_2 from "../Statics/Img/PowerBi/Dash2.png";
import IMG_Dash_3 from "../Statics/Img/PowerBi/Dash3.png";
import IMG_Dash_4 from "../Statics/Img/PowerBi/Dash4.png";

export const VirtualDashboardVideoMenu = () => {

    const [searchParam, setSearchParam] = useState(""); // State variable for storing search parameter

    const HandleSearchChange = (e) => { // Function to handle search input change
        setSearchParam(e.target.value); // Update search parameter state
    }

    useEffect(() => { // Effect hook to fetch data when searchParam changes
        ClearDashboards(); // Clear existing Dashboards
        fetchData(searchParam); // Fetch data based on searchParam
    }, [searchParam]); // Depend on searchParam to trigger re-fetching

    const ClearDashboards = () => { // Function to clear Dashboards state
        setDashboards([]); // Clear Dashboards state
    };
    const fetchData = async (Description_Video) => { // Function to fetch data from the server
        try {
            let response;
            // Fetch data based on a search query
            response = await fetch('http://localhost/CRM%20TCCE/TCCE_CO/tcce_co/src/Apis/ApiDashboards.php?op=Consult_Dashboard', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Description_Dashboard: Description_Video, // Include search query in request body
                })
            });

            const data = await response.json(); // Parse response data

            // Check if data.items is an array, if not, convert it into an array
            let dashboardsData;
            if (Array.isArray(data.items)) {
                dashboardsData = data.items; // If data.items is already an array, assign it directly
            } else {
                // If data.items is not an array, create an array and add the single object
                dashboardsData = data.items ? [data.items] : [];
            }
            setDashboards(dashboardsData); // Update Dashboards state with fetched data
        } catch (error) {
            console.error('Error fetching Dashboards:', error); // Log error if fetching fails
        }
    }
    const [Dashboards, setDashboards] = useState([]); // State variable for storing Dashboards data


    const HandleClickVideoLibrary = ({id}) => () => {
        localStorage.setItem('optionSwitchHome', 2);
        localStorage.setItem('idDashboard', id);
        window.location.href = '/Home';

    }

    const backgroundImageMap = {
        1: IMG_Dash_1,
        2: IMG_Dash_2,
        3: IMG_Dash_3,
        4: IMG_Dash_4,
    };

    // Función para obtener la imagen de fondo según el Id_Dashbord
    const getBackgroundImage = (Id_Dashbord) => {
        return backgroundImageMap[Id_Dashbord] || null;
    };

    return (
        <>
            <NavbarLeftVirtualLibraryComponent />
            <nav id="Navbar_Admin_Top">
                <form id="Form_Navbar_Top_Admin_Search">
                    <label id="Label_Navbar_Top_Admin_Search" htmlFor="search">
                        <input
                            id="Input_Navbar_Top_Admin_Search"
                            type="text"
                            required=""
                            placeholder="Search Dashboard"
                            className="search"
                            onChange={HandleSearchChange}
                        />
                        <div id="Fancy_BG_Navbar_Top_Admin_Search"></div>
                        <div id="Search_Navbar_Top_Admin_Search">
                            <svg viewBox="0 0 24 24" aria-hidden="true" id="SVG_Navbar_Top_Admin_Search"
                                className=" r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
                                <g>
                                    <path
                                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                                </g>
                            </svg>
                        </div>
                        <button className="close-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" id="SVG_Navbar_Top_Admin_Search"
                                className=" h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </label>
                </form>


                <section id="Section_Navbar_Top_Admin_Widgets">
                    <div id="Div_Navbar_Top_Admin_Widgets_Search">

                    </div>
                    <div id="Div_Navbar_Top_Admin_Widgets_Notifications">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                            className="bi bi-bell-fill" viewBox="0 0 16 16">
                            <path
                                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                        </svg>
                    </div>
                    <div id="Div_Navbar_Top_Admin_Widgets_Messages">

                        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                            className="bi bi-chat-left-text-fill" viewBox="0 0 16 16">
                            <path
                                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
                        </svg>
                    </div>

                </section>
                <section id="Section_Navbar_Top_Admin_Profile">
                    <div id="Div_Navbar_Top_Admin_Profile_Info">
                        <div id="Div_Navbar_Top_Admin_Profile_Name">
                            <p style={{
                                fontFamily: 'cursive, serif',
                                fontSize: '1.5rem',
                                padding: '0',
                                margin: '0'
                            }}>{localStorage.getItem('Complete_Name')}</p>
                        </div>
                        <div id="Div_Navbar_Top_Admin_Profile_Type_Employee">
                            <p style={{ fontFamily: 'inherit' }}>Admin</p>
                        </div>
                    </div>
                    {localStorage.getItem('Complete_Name') === "Stiven Mazo" ?
                        <div id="Div_Navbar_Top_Admin_Profile_Icon"
                            style={{ background: `url(${IMG_Example_Profile_Stiven}) no-repeat center center / contain` }}></div>
                        : <div id="Div_Navbar_Top_Admin_Profile_Icon"
                            style={{ background: `url(${IMG_Example_Profile_Ander}) no-repeat center center / contain` }}></div>}
                </section>
            </nav>
            <section id="Section_Video_Library">
                {Dashboards.map((library, index) => (
                    <div id="Card_Selection_Of_Settings" className="card" key={`${index}-${library.Id_Dashbord}`}
                        onClick={HandleClickVideoLibrary({ id: library.Id_Dashbord })}>
                        <div style={{
                            background: `url(${getBackgroundImage(library.Id_Dashbord)}) no-repeat center center / cover`
                        }} className="card-image-container">
                        </div>
                        <h5 id="Tittle_Card_Selection_Of_Settings" className="card-title">Id: {library.Id_Dashbord}</h5>
                        <p id="Tittle_Card_Selection_Of_Settings" className="card-title">
                            <span>Description: </span>{library.Desc_Dashboard}
                        </p>
                      
                    </div>
                ))}
            </section>

        </>
    )
}
