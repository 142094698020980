import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Login from './JS/Login';
import Home from './JS/Home';
import AdminVideos from './JS/AdminVideos';
import {VirtualLibraryVideoMenu} from "./JS/VirtualLibraryVideoMenu";
import AdminPowerBi from "./JS/AdminPowerBi";
import {VirtualDashboardVideoMenu} from './JS/VirtualDashboardVideoMenu';
import Components from "./JS/Components";
import Categories from "./JS/Categories";
import AdminUsers from "./JS/AdminUsers";
// import Work_Areas from "./JS/Work_Areas";
import GeneralContractors from "./JS/GeneralContractors";
import Roles from "./JS/Roles";
import Trades from "./JS/Trades";
import Materials from "./JS/Materials";
import EquipmentMovesLogs from "./JS/EquipmentMovesLogs";
import Jobs from "./JS/Jobs";
import AdminWorkAreas from "./JS/Trades";
import {InventoryEquipment} from "./JS/inventory-equipment/InventoryEquipment";

// App component
function App() {
    // State variable to store connection status
    const [Connect, setConnect] = useState(() => {
        try {
            const storedConnect = localStorage.getItem('connect'); // Get Connect status from localStorage
            return storedConnect ? JSON.parse(storedConnect) : false; // Parse stored value as JSON, default to false
        } catch (error) {
            console.error("Error parsing JSON from localStorage:", error); // Log error if parsing fails
            return false; // Return false if parsing fails
        }
    });

    // Function to handle access
    const HandleAccess = (state) => {
        console.log(state); // Log state
        let Connect_Status = state.ok; // Get connection status
        setConnect(Connect_Status); // Update connection status
        localStorage.setItem('connect', JSON.stringify(Connect_Status)); // Save connection status
        localStorage.setItem('id_user', state.id_user); // Save user ID in localStorage
        localStorage.setItem('Complete_Name', state.Complete_Name); // Save username in localStorage
        localStorage.setItem('Email', state.Email); // Save email in localStorage
        localStorage.setItem('Cel', state.Cel); // Save phone number in localStorage
        localStorage.setItem('Telephone', state.Telephone); // Save phone number in localStorage
        localStorage.setItem('Created_at', state.Created_at); // Save creation date in localStorage
        localStorage.setItem('Update_at', state.Update_at); // Save update date in localStorage
    };

    // Function to handle access
    useEffect(() => {
        let timeOutId; // Variable to store timeout ID

        // Function to reset connection status
        const resetConnect = () => {
            setConnect(false); // Update connection status
            localStorage.setItem('connect', JSON.stringify(false)); // Save connection status in localStorage
            console.log("Se ha restablecido Connect a false debido a la inactividad"); // Log message
        };

        // Function to handle user activity
        const HandleUserActivity = () => {
            clearTimeout(timeOutId); // Clear timeout
            timeOutId = setTimeout(resetConnect, 10 * 60 * 1000); // Set timeout to reset connection status after 10 minutes of inactivity
        }

        // Add event listeners for user activity
        window.addEventListener('mousemove', HandleUserActivity); // Add event listener for mouse movement
        window.addEventListener('keydown', HandleUserActivity); // Add event listener for keydown events
        window.addEventListener('click', HandleUserActivity); // Add event listener for click events

        // Return cleanup function
        return () => {
            window.removeEventListener('mousemove', HandleUserActivity); // Remove event listener for mouse movement
            window.removeEventListener('keydown', HandleUserActivity); // Remove event listener for keydown events
            window.removeEventListener('click', HandleUserActivity); // Remove event listener for click events
            clearTimeout(timeOutId); // Clear timeout
        };
    }, []);

    return (
        <Router> {/* Router component for routing */}
            <Routes> {/* Routes component for defining routes */}
                <Route
                    path="/"
                    element={Connect ? <Navigate to="/home"/> : <Login Access={HandleAccess}/>}
                /> {/* Route for a Login component */}
                <Route
                    path="/home"
                    element={Connect ? <EquipmentMovesLogs/> : <Navigate to="/"/>}
                /> {/* Route for a Home component */}
                <Route
                    path="/AdminVideos"
                    element={Connect ? <AdminVideos/> : <Navigate to="/"/>}
                /> {/* Route for an AdminVideos component */}
                <Route
                    path="/Library"
                    element={Connect ? <VirtualLibraryVideoMenu/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualLibraryVideoMenu component */}
                <Route
                    path="/AdminPowerBi"
                    element={Connect ? <AdminPowerBi/> : <Navigate to="/"/>}
                /> {/* Route for an AdminPowerBi component */}
                <Route
                    path="/Components"
                    element={Connect ? <Components/> : <Navigate to="/"/>}
                /> {/* Route for a PowerBi component */}
                <Route
                    path="/Categories"
                    element={Connect ? <Categories/> : <Navigate to="/"/>}
                /> {/* Route for a PowerBi component */}
                <Route
                    path='/PowerBi'
                    element={Connect ? <VirtualDashboardVideoMenu/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}

                <Route
                    path='/WorkAreas'
                    element={Connect ? <AdminWorkAreas/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}

                <Route
                    path='/GeneralContractors'
                    element={Connect ? <GeneralContractors/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}

                <Route
                    path='/Materials'
                    element={Connect ? <Materials/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}

                <Route
                    path='/Roles'
                    element={Connect ? <Roles/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}

                <Route
                    path='/Trades'
                    element={Connect ? <Trades/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}


                <Route
                    path='/Jobs'
                    element={Connect ? <Jobs/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}


                <Route
                    path='/EquipmentMovesLogs'
                    element={Connect ? <EquipmentMovesLogs/> : <Navigate to="/"/>}
                /> {/* Route for a VirtualDashboardVideoMenu component */}

                <Route
                    path='/AdminUsers'
                    element={Connect ? <AdminUsers/> : <Navigate to="/"/>}
                /> {/* Route for an AdminUsers component */}
                <Route
                    path='/InventoryEquipment'
                    element={Connect ? <InventoryEquipment/> : <Navigate to="/"/>}
                /> {/* Route for an InventoryEquipment component */}
            </Routes>
            {/* Left Navbar */}
        </Router>
    );
}

export default App; // Export App component as default
