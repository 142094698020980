import React, {useEffect} from 'react' // Import the useEffect hook from React
import '../Styles/NavbarLeftVirtualLibraryStyle.css' // Import the CSS file for the Navbar component
import IMG_Example_Profile_Stiven from '../Statics/Img/stiven.jpg'; // Import the image for the Profile dropdown menu
import IMG_Example_Profile_Ander from '../Statics/Img/Ander.jpg'; // Import the image for the Profile dropdown menu
import Navbar from "./Navbar"; // Import the Navbar component
import {useLocation} from "react-router-dom";
import {InventoryEquipment} from "./inventory-equipment/InventoryEquipment";


export const NavbarLeftVirtualLibraryComponent = () => { // Navbar component for the virtual library

    const location = useLocation();
    const isLibraryPage = location.pathname === '/Library';


    // State variable to manage the Profile dropdown menu
    const [Profile, SetProfile] = React.useState(false);

    // State variable to manage the Settings dropdown menu
    const [Settings, SetSettings] = React.useState(false);

    // State variable to manage the Library dropdown menu
    const [Library_Navbar, SetLibrary_Navbar] = React.useState(false);


    // State variable to manage the Library dropdown menu
    const [Library_Navbar_Complete, SetLibrary_Navbar_Complete] = React.useState(false);


    // Function to handle the click event on the Profile icon
    const HandleClickProfile = () => {
        // Toggle the Profile dropdown menu
        SetProfile(!Profile);
    }

    // Function to handle the click event on the Settings icon
    const HandleClickSettings = () => {
        // Toggle the Settings dropdown menu
        SetSettings(!Settings);
    }

    const HandleClickLibrary = () => {
        SetLibrary_Navbar(!Library_Navbar);
    }

    const HandleClickLibraryComplete = () => {
        SetLibrary_Navbar_Complete(!Library_Navbar_Complete);
    }

    // Function to handle the click event on the Navbar icons
    const HandleClickSwitch = (option) => {
        // Check if the option is "Exit"
        if (option === "Exit") {
            localStorage.setItem('optionSwitchHome', 0);
            localStorage.setItem('connect', JSON.stringify(false));
            window.location.reload();
        }
        if (option === "Home") {
            localStorage.setItem('optionSwitchHome', 0);
            window.location.href = '/home';
        } else {
            // Redirect to the corresponding page based on the option selected
            window.location.href = redirections[option] || redirections["Home"];
        }
    };


    // Object to store the redirection paths
    const redirections = {
        // Object to store the redirection paths
        Home: "/home", // Path to the Home page
        User: "/AdminUsers", // Path to the User page
        Admin: "/AdminVideos", // Path to the AdminVideos page
        PowerBi: "/PowerBi", // Path to the AdminPowerBi page
        Library: "/Library", // Path to the Library page
        Library_Admin: "/AdminPowerBi", // Path to the Library_Admin page
        Inventory: "/InventoryEquipment", // Path to the Inventory page
        Exit: "/",  // Path to the Exit page
    };

    // Function to handle the click event on the Navbar icons
    useEffect(() => {
        const handleUnload = () => {
            SetProfile(false); // Close the Profile dropdown menu
        };

        window.addEventListener('beforeunload', handleUnload); // Add an event listener to the window object
        return () => window.removeEventListener('beforeunload', handleUnload);  // Remove the event listener when the component is unmounted
    }, []);

    return (
        <>
            <nav id="Navbar_Admin_Left">
                {/*<div id="Div_Navbar_Admin_Left_Icon_Profile" onClick={HandleClickProfile}>*/}
                {/*    <svg id="Svg_Navbar_Admin_Left_Icon_Profile" xmlns="http://www.w3.org/2000/svg" width="8vh"*/}
                {/*         height="8vh" fill="currentColor"*/}
                {/*         className="bi bi-person-fill" viewBox="0 0 16 16">*/}
                {/*        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>*/}
                {/*    </svg>*/}
                {/*    <div id="Tooltip_Navbar_Left_Profile">*/}
                {/*        Profile*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div id="Div_Navbar_Admin_Left_Icon_Home_Library" onClick={() => HandleClickSwitch('Home')}
                >
                    <svg id="Svg_Navbar_Admin_Left_Icon_Home" xmlns="http://www.w3.org/2000/svg" width="6vh"
                         height="6vh" fill="currentColor"
                         className="bi bi-house-door-fill" viewBox="0 0 16 16">
                        <path
                            d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5"/>
                    </svg>
                    <div id="Tooltip_Navbar_Left_Home">
                        Home
                    </div>
                </div>
                {/*<div id="Div_Navbar_Admin_Left_Icon_Virtual_Library"*/}
                {/*     onDoubleClick={isLibraryPage ? () => HandleClickSwitch('Library') : null}*/}
                {/*     onClickCapture={isLibraryPage ? HandleClickLibrary : null}*/}
                {/*     onClick={isLibraryPage ? null : () => HandleClickSwitch('Library')}>*/}
                {/*    <svg id="Svg_Navbar_Admin_Left_Icon_Virtual_Library" xmlns="http://www.w3.org/2000/svg" width="6vh"*/}
                {/*         height="6vh" fill="currentColor"*/}
                {/*         className="bi bi-youtube" viewBox="0 0 16 16">*/}
                {/*        <path*/}
                {/*            d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>*/}

                {/*    </svg>*/}
                {/*    {isLibraryPage ? (*/}
                {/*        <svg id="Svg_Navbar_Admin_Left_Icon_Virtual_Library_Search"*/}
                {/*             onClick={HandleClickLibraryComplete}*/}
                {/*             xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                {/*             className="bi bi-search" viewBox="0 0 16 16">*/}
                {/*            <path*/}
                {/*                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>*/}
                {/*        </svg>) : null*/}
                {/*    }*/}
                {/*    <div id="Tooltip_Navbar_Left_Library">*/}
                {/*        Virtual Library*/}
                {/*    </div>*/}

                {/*</div>*/}
                {/*<div id="Div_Navbar_Admin_Left_Icon_Bar_Chart" onClick={() => HandleClickSwitch('PowerBi')}>*/}
                {/*    <svg id="Svg_Navbar_Admin_Left_Icon_Bar_Chart" xmlns="http://www.w3.org/2000/svg" width="5vh"*/}
                {/*         height="5vh" fill="currentColor"*/}
                {/*         className="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">*/}
                {/*        <path*/}
                {/*            d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1z"/>*/}
                {/*    </svg>*/}
                {/*    <div id="Tooltip_Navbar_Left_Bar_Chart">*/}
                {/*        Dashboards and Reports*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div id="Div_Navbar_Admin_Left_Icon_Setting"*/}
                {/*    // onClick={() => HandleClickSwitch('AdminVideos')}*/}
                {/*     onClick={HandleClickSettings}*/}
                {/*>*/}
                {/*    <svg id="Svg_Navbar_Admin_Left_Icon_Setting" xmlns="http://www.w3.org/2000/svg" width="6vh"*/}
                {/*         height="6vh" fill="currentColor"*/}
                {/*         className="bi bi-gear-wide-connected" viewBox="0 0 16 16">*/}
                {/*        <path*/}
                {/*            d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z"/>*/}
                {/*    </svg>*/}
                {/*    <div id="Tooltip_Navbar_Left_Settings">*/}
                {/*        Settings*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div id="Div_Navbar_Admin_Left_Icon_Setting"
                    // onClick={() => HandleClickSwitch('AdminVideos')}
                     onClick={() => HandleClickSwitch('Inventory')}
                >
                    <svg id="Svg_Navbar_Admin_Left_Icon_Setting" xmlns="http://www.w3.org/2000/svg" width="6vh"
                         height="6vh" fill="currentColor"
                         className="bi bi-gear-wide-connected" viewBox="0 0 16 16">
                        <path
                            d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z"/>
                    </svg>
                    <div id="Tooltip_Navbar_Left_Settings">
                        Settings
                    </div>
                </div>
                <div id="Div_Navbar_Admin_Left_Icon_Exit" onClick={() => HandleClickSwitch('Exit')}>
                    <svg id="Svg_Navbar_Admin_Left_Icon_Exit" xmlns="http://www.w3.org/2000/svg" width="6vh"
                         height="6vh" fill="currentColor"
                         className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                        <path fillRule="evenodd"
                              d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                    </svg>
                    <div id="Tooltip_Navbar_Left_Exit">
                        Exit
                    </div>
                </div>
            </nav>
            {Profile && (
                <section id="Section_Navbar_Profile_Virtual_Library">
                    <div id="Card_Navbar_Profile_Virtual_Library" className="card">
                        {/* Detalles del perfil del usuario */}
                        <div className="blob"></div>
                        <button id="Button_Close_Profile_Admin_Card" className="button" onClick={HandleClickProfile}>
                            <span className="X"></span>
                            <span className="Y"></span>
                            <div className="close">Close</div>
                        </button>
                        {localStorage.getItem('Complete_Name') === "Stiven Mazo" ?
                            <span className="img"
                                  style={{background: `url(${IMG_Example_Profile_Stiven}) no-repeat center center / contain`}}></span>
                            : <span className="img"
                                    style={{background: `url(${IMG_Example_Profile_Ander}) no-repeat center center / contain`}}></span>}
                        <h2>{localStorage.getItem('Complete_Name') || "N/A"}</h2>
                        <div>
                            <label htmlFor="id_user">ID: {localStorage.getItem('id_user') || "N/A"}</label>
                            <label htmlFor="email">Email: {localStorage.getItem('Email') || "N/A"}</label>
                            <br/>
                            <label htmlFor="cel">Cel: {localStorage.getItem('Cel') || "N/A"}</label>
                            <label htmlFor="telephone">Telephone: {localStorage.getItem('Telephone') || "N/A"}</label>
                            <br/>
                            <label htmlFor="created_at">Create: {localStorage.getItem('Created_at') || "N/A"}</label>
                            <label
                                htmlFor="updated_at">Update: {localStorage.getItem('Update_at') || "N/A"}</label>
                        </div>
                    </div>
                </section>
            )}
            {Settings && (
                <section id="Section_Navbar_Settings_Dropdown_Virtual_Library">
                    <div className="card" onClick={() => HandleClickSwitch('Admin')}>
                        <div style={{
                            backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png)',
                            backgroundSize: 'cover'
                        }} className="card-image-container">
                        </div>
                        <h5 className="card-title">Video settings</h5>
                    </div>
                    <div className="card" onClick={() => HandleClickSwitch('Library_Admin')}>
                        <div style={{
                            backgroundImage: 'url(https://datascientest.com/es/wp-content/uploads/sites/7/2020/10/power-bi-logo-1.jpg)',
                            backgroundSize: 'cover'
                        }} className="card-image-container">
                        </div>

                        <h5 className="card-title">PowerBi settings</h5>
                    </div>
                    <div className="card" onClick={() => HandleClickSwitch('User')}>
                        <div style={{
                            backgroundImage: 'url(https://t4.ftcdn.net/jpg/02/27/45/09/360_F_227450952_KQCMShHPOPebUXklULsKsROk5AvN6H1H.jpg)',
                            backgroundSize: 'cover'
                        }} className="card-image-container">
                        </div>
                        <h5 className="card-title">User settings</h5>
                    </div>
                </section>
            )
            }

            {/*{Library_Navbar && (*/}
            {/*    <div id="Div_Library_Navbar" className="active" onClick={HandleClickLibraryComplete}>*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"*/}
            {/*             className="bi bi-caret-right-fill" viewBox="0 0 16 16">*/}
            {/*            <path*/}
            {/*                d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*)}*/}

            {Library_Navbar_Complete && (
                <Navbar HandleClick={HandleClickLibraryComplete}/>
            )
            }
        </>
    )
}
