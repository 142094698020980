import React from 'react';
import '../Styles/Navbar.css';
import { Link } from "react-router-dom";

const Navbar = ({ HandleClick }) => {
    const handleClick = () => {
        window.location.href = "/";
    }

    const HandleClickAdmin = () => {
        window.location.href = "/Library";
    }

    return (
        <div className="Navbar_Component">
            <button className="button" onClick={HandleClickAdmin}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
                     className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                    <path
                        d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
            </button>

            <div className="Logo_Total_Navbar_Component">
                <div className="Image_Logo_Total_Navbar_Component"></div>
            </div>

            <div className="offcanvas-body">
                <form id="Search_Navbar" className="d-flex mt-3" role="search">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                    <button id="Button_Navbar_Component_Search" className="btn" type="submit">Search</button>
                </form>
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <li className="nav-item dropdown">
                        <button className="nav-link dropdown-toggle text-white"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                                 className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg>
                            Category
                        </button>
                        <ul className="dropdown-menu bg-white">
                            <li>
                                <button className="dropdown-item" >
                                    <Link to="/admin">Admin</Link>
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item">Another action</button>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li>
                                <button className="dropdown-item">Something else here</button>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className="Exit_Navbar_Component">
                    <button className="Exit_Button" type="submit" onClick={handleClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
                             className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                            <path fillRule="evenodd"
                                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                        </svg>
                        Exit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
