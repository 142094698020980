import React from 'react';
import { useState } from "react"; // Importing hooks from React
import "../Styles/CarouselLoginVirtualLibrary.css"; // Importing CSS file
import { Carousel, CarouselControl, CarouselIndicators } from "reactstrap"; // Importing components from reactstrap
import Image1 from "../Statics/Img/Background1.jpg"; // Importing image file
import Image2 from "../Statics/Img/Background2.jpg"; // Importing image file
import Image3 from "../Statics/Img/Images TCC/2.jpg"; // Importing image file
import Image4 from "../Statics/Img/Images TCC/image6.jpeg"; // Importing image file
import Image5 from "../Statics/Img/Images TCC/image10.jpeg"; // Importing image file




// Functional component View1
const View1 = ({ SetAnimating }) => {
    const [moreTotalConstruction, setMoreTotalConstruction] = useState(false);
    const [wordButton, setWordButton] = useState("More");

    const handleMoreTotalConstruction = () => {
        setMoreTotalConstruction(prevState => !prevState);
        SetAnimating(!moreTotalConstruction);
        setWordButton(prevState => prevState === "More" ? "Less" : "More");
    }

    return (
        <div id="Container_View1_Login_Carousel" className="fade-in">
            <div id="Div_Left_View1_Carousel_Login" className="md:m-3">
                <div id="Image1_View1_Login_Carousel">
                    <img src={Image1} alt="" />
                </div>
                <div id="Image2_View1_Login_Carousel">
                    <img src={Image2} alt="" />
                </div>
            </div>
            <div id="Div_Right_View1_Carousel_Login">
                <section id="Section_More_View1_Carousel_Login" className="lg:flex lg:text-end h-75">
                    <h1 id="Tittle_More_View1_Carousel_Login">Total Civil Construction</h1>
                    <button
                        id={`Button_More_View1_Carousel_Login${moreTotalConstruction ? '.Dropdown' : ''}`}
                        onClick={handleMoreTotalConstruction}
                    >
                        <p> {moreTotalConstruction ? "" : "More"}
                        </p>
                    </button>

                    {moreTotalConstruction && (
                        <div id="Div_More_Total_Construction">
                            {/*<h1 id="Tittle_More_Total_Construction"></h1>*/}
                            <p id="Parraphal_More_Total_Construction"><span id='Span_Parraphal_More_Total_Construction'>Welcome to TCCE!</span>
                                With over two decades of experience in civil works, we’ve built a team of seasoned professionals who specialize in constructing roads, bridges, and buildings. Our mission is to deliver unparalleled service excellence to our customers through seamless coordination in our activities, incorporating the industry’s best skilled force. Guided by a commitment to professional management practices, we strive to exceed expectations, foster innovation, and create lasting value for our clients and stakeholders. Our goal is to ensure successful projects and sustainable growth for both our clients and our company. Reach out to us today to learn more about our services and how we can assist with your next project. 👷‍♂️🏗️
                            </p>
                            <button id="Button_More_View1_Carousel_Login"
                                onClick={handleMoreTotalConstruction}>{wordButton}</button>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};


// Functional component View2
const View2 = ({ SetAnimating }) => {
    const [moreTotalConstruction, setMoreTotalConstruction] = useState(false);

    const HandleMoreTotalConstruction = () => {
        setMoreTotalConstruction(!moreTotalConstruction);
        SetAnimating(!moreTotalConstruction);
    }
    return (
        // JSX markup for a View2 component
        <div id="Container_View2_Login_Carousel" className="fade-in">
            <img id="Image1_View2_Login_Carousel" src={Image3} alt="" />
            <h1 id="Tittle_More_View2_Carousel_Login">About us</h1>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p id="Parraphal_More_View2_Carousel_Login" onClick={HandleMoreTotalConstruction}>"Strive not to be a
                success, but rather to be of value" ~
                Albert Einstein</p>
            {
                moreTotalConstruction && (
                    <div id="Div_More_Total_Construction_View2">
                        {/*<h1 id="Tittle_More_Total_Construction_View2"></h1>*/}
                        <p id="Parraphal_More_Total_Construction_View2">
                            For over two decades, Total Civil Construction has tackled complex projects, found solutions to challenges, and achieved success in regions surrounding the nation’s capital. Our foundation lies in forging healthy partnerships with clients. Through ethical business practices, shared vision, and ongoing communication, we turn drawings into deliverables. Expert analysis, value engineering, and industry-specific project management technology allow us to track time and cost throughout all phases of a project. Our performance model relies on real-time analysis to identify emerging issues and implement corrective action with optimal efficiency.
                            <br /><br />
                            Our commitment remains unwavering:
                            <ul id='Ul_Parraphal_More_View2_Carousel_Login'>
                                <li>We complete projects within budget and on time.</li>
                                <li>We do not compromise on safety protocols and quality assurance.</li>
                                <li>These objectives hold true regardless of the size and scope of the project.</li>
                            </ul>
                        </p>

                        <button id="Button_More_View2_Carousel_Login"
                            onClick={HandleMoreTotalConstruction}>Less
                        </button>
                    </div>

                )
            }
        </div>


    )
        ;
};

// Functional component View3
const View3 = ({ SetAnimating }) => {
    const [moreTotalConstruction, setMoreTotalConstruction] = useState(false);
    const [wordButton, setWordButton] = useState("More");

    const handleMoreTotalConstruction = () => {
        setMoreTotalConstruction(prevState => !prevState);
        SetAnimating(!moreTotalConstruction);
        setWordButton(prevState => prevState === "More" ? "Less" : "More");
    }


    return (
        // JSX markup for a View3 component
        <div id="Container_View3_Login_Carousel" className="fade-in">
            <div id="Div_Left_View3_Carousel_Login">
                <section id="Section_More_View3_Carousel_Login">
                    <h1 id="Tittle_Services_View3_Carousel_Login">Services</h1>
                    <p id="Parraphal_Services_View3_Carousel_Login">OUR CORPORATE PHILOSOPHY IS OUR BUSINESS MODEL</p>
                    <button id={`Button_More_View3_Carousel_Login${moreTotalConstruction ? 'Dropdown' : ''}`}
                        onClick={handleMoreTotalConstruction}>More
                    </button>
                    {moreTotalConstruction && (
                        <div id="Div_More_Total_Construction_View3">
                            {/*<h1 id="Tittle_More_Total_Construction_View3"></h1>*/}
                            <p id="Parraphal_More_Total_Construction_View3">
                                <span> Civil Construction specializes in: </span>
                                <ul>
                                    <li>Clearing & Demolition</li>
                                    <li>Disaster/Environmental Reclamation & Recovery</li>
                                    <li>Earthwork</li>
                                    <li>Concrete & Asphalt Pavement</li>
                                    <li>Wet & Dry Utility Infrastructure</li>
                                </ul>                           
                                <br />
                                Our diverse client base is comprised of public and privately owned interests, and general contractors, as well as local, state and federal government agencies.
                                <br /><br />
                                <span>Total Civil Construction's industrial projects portfolio includes:</span>
                                <ul>
                                    <li>Site Preparation: Commercial, Residential, Institutional, Industrial</li>
                                    <li>Transportation Infrastructure: Roads & Bridges, Rail Lines, Airports</li>
                                    <li>Utility Infrastructure: Water, Sanitary Sewer, Storm Drainage, Electric/Telecom Conduits</li>
                                    <li>Parks and Environmental Reclamation.</li>
                                </ul>
                                <br />
                                <h5>Total Civil Construction, We Bring Value to Your Vision</h5>
                                <br />
                                Contact Us: Submit a bid invitation to <a href="estimating@tcce.biz">estimating@tcce.biz</a> 
                            </p>
                            <button id="Button_More_View3_Carousel_Login"
                                onClick={handleMoreTotalConstruction}>{wordButton}</button>
                        </div>
                    )}
                </section>
            </div>
            <div id="Div_Right_View3_Carousel_Login">
                <div id="Image1_View3_Login_Carousel">
                    <img src={Image4} alt="" />
                </div>
                <div id="Image2_View3_Login_Carousel">
                    <img src={Image5} alt="" />
                </div>
            </div>
        </div>
    );
};


// const Items array containing objects with a component and key properties
const Items = [
    {
        // First object representing a View1 component
        component: <View1 />,
        key: 1,
    },
    {
        // Second object representing a View2 component
        component: <View2 />,
        key: 2,
    },
    {
        // Third object representing a View3 component
        component: <View3 />,
        key: 3,
    },
];

export const CarouselLoginVirtualLibrary = (args) => {
    //------------------------------------CAROUSEL------------------------------------//
    //----------------------------OBJECTS------------------------------------//

    // Declares a state variable 'ActiveIndex' using the 'useState' hook,
    const [ActiveIndex, SetActiveIndex] = useState(0);

    // Declares a state variable 'Animating' using the 'useState' hook,
    const [Animating, SetAnimating] = useState(false);

    //----------------------------FUNCTIONS------------------------------------//

    // Defines a function 'next' which increments the value of 'ActiveIndex' by 1
    const Next = () => {
        if (Animating) return;
        SetAnimating(true); // Starts the animation
        const NextIndex = ActiveIndex === Items.length - 1 ? 0 : ActiveIndex + 1;
        SetActiveIndex(NextIndex);
        setTimeout(() => {
            SetAnimating(false); // Ends the animation after 0.5s (same duration as the CSS animation)
        }, 500);
    };

    // Defines a function 'previous' which decrements the value of 'ActiveIndex' by 1
    const Previous = () => {
        if (Animating) return;
        const NextIndex = ActiveIndex === 0 ? Items.length - 1 : ActiveIndex - 1;
        SetActiveIndex(NextIndex);
    };

    // Defines a function 'goToIndex' which sets the value of 'ActiveIndex' to the index passed as an argument
    const GoToIndex = (newIndex) => {
        if (Animating) return;
        SetActiveIndex(newIndex);
    };


    return (
        <>
            {/* Carousel component */}
            <Carousel
                activeIndex={ActiveIndex}
                next={Next}
                previous={Previous}
                interval={10000}
                id="Carousel_Login"
                pause={false}
                ride="carousel"
                keyboard={true}
                touch={true}
                slide={true}
                fade={true}
                indicators={false}
                controls={false}
                {...args}
            >
                {/* Carousel Indicators */}
                <CarouselIndicators
                    items={Items}
                    activeIndex={ActiveIndex}
                    onClickHandler={GoToIndex}
                />
                {/* Carousel items */}
                {Items.map((item, index) => (
                    <div
                        key={index}
                        className={`carousel-item${index === ActiveIndex ? " active" : ""}`}
                    >
                        {React.cloneElement(item.component, { SetAnimating })}
                    </div>
                ))}
                {/* Carousel control - Previous */}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={Previous}
                />
                {/* Carousel control - Next */}
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={Next}
                />
            </Carousel>
        </>
    )
}