//Imports needed for the component
import React, {useCallback, useEffect, useRef} from 'react'; // Importing the necessary modules
import '../../Styles/AddEquipmentMoves.css'; // Importing CSS styles for the Admin component
import {useState} from "react"; // Importing useState hook from React
import { // Importing specific components
    NavbarLeftVirtualLibraryComponent
} from "../NavbarLeftVirtualLibraryComponent";
import IMG_Example_Profile_Stiven from "../../Statics/Img/stiven.jpg";
import IMG_Example_Profile_Ander from "../../Statics/Img/Ander.jpg";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


// Function for sending Apis request

const Send_Apis_request = async (Url, Data, Method) => { // Function for sending update request
    let Response_Get; // Variable for storing response
    let Response_Post; // Variable for storing response
    switch (Method) {
        case  'POST':
            try {
                Response_Post = await fetch(Url, { // Sending POST request
                    method: 'POST', body: JSON.stringify(Data), // Converting data to JSON format
                    headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Post.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }

            } catch (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Post;
        case 'GET':
            try {
                Response_Get = await fetch(Url, { // Sending POST request
                    method: 'GET', headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Get.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }
            } catch (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Get;
        default:
            console.log("Method not found");
            break;
    }
};


// principal function
export const InventoryEquipment = () => {


    //----------------------------USE STATE--------------------------------
    // State variables for managing the component state--Variables de estado para gestionar el estado del componente.
    const [Global_Cod_Equipment, setGlobal_Cod_Equipment] = useState(0); // State variable for storing the global ID of the library
    const [Global_Description_Components, setGlobal_Description_Components] = useState(""); // State variable
    // for storing the global installation inspection
    const [EditActive, SetEditActive] = useState(false) // State variable for managing edit mode activation
    const [AddActive, SetAddActive] = useState(false) // State variable for managing adds mode activation
    const [Components, setComponents] = useState([]); // State variable for storing Dashboards data
    const [searchParam, setSearchParam] = useState(""); // State variable for storing search parameter
    const [Global_Date_Moved, setGlobal_Date_Moved] = useState(""); // State variable
    const [Global_Id_Asset_Type, setGlobal_Id_Asset_Type] = useState(""); // State variable
    const [Global_Description_Asset_Type, setGlobal_Description_Asset_Type] = useState(""); // State variable
    const [Global_Id_equipment, setGlobal_Id_equipment] = useState(""); // State variable
    const [Global_Description_Equipment_Type, setGlobal_Description_Equipment_Type] = useState(""); // State variable
    const [Global_Description_Equipment, setGlobal_Description_Equipment] = useState(""); // State variable
    const [Global_Equipment_Description, setGlobal_Equipment_Description] = useState(""); // State variable
    const [Global_Serial_Equipment, setGlobal_Serial_Equipment] = useState(""); // State variable
    const [Global_Id_Equipment_Type, setGlobal_Id_Equipment_Type] = useState(""); // State variable
    const [Global_Start_Reporting_On, setGlobal_Start_Reporting_On] = useState(""); // State variable
    const [Global_To_Stop_Reporting_On, setGlobal_To_Stop_Reporting_On] = useState(""); // State variable


    //----------------------------USE REF--------------------------------
    const Description_Update_Ref = useRef(null); // Ref for the description input field in update mode
    const Descripcion_Component_Add_Ref = useRef(null); // Ref for the Info of Component input field in added mode
    const Id_Asset_Type_Update_Ref = useRef(null); //
    const Cod_Equipment_Update_Ref = useRef(null); //
    const Equipment_Description_Update_Ref = useRef(null); //
    const Serial_Equipment_Update_Ref = useRef(null); //
    const Id_Equipment_Type_Update_Ref = useRef(null); //
    const To_Stop_Reporting_On_Update_Ref = useRef(null); //
    const Date_Moved_Update_Ref = useRef(null); //


    const Cod_Equipment_Add_Ref = useRef(null); // Ref for the Date Moved input field
    const Id_Asset_Type_Add_Ref = useRef(null); // Ref for the Id Asset Type input field
    const Id_equipment_Add_Ref = useRef(null); // Ref for the Id equipment input field
    const Equipment_Description_Add_Ref = useRef(null); // Ref for the Id Job Destination input field
    const Serial_Equipment_Add_Ref = useRef(null); // Ref for the Start Reporting On input field
    const Id_Equipment_Type_Add_Ref = useRef(null); // Ref for the To Stop Reporting On input field

    const Origin_Job_Add_Ref = useRef(null); // Ref for the Origin Job input field

    //----------------------------Functions--------------------------------


    const HandleActiveSwitch = async (Id_Components, Active) => { // Function for handling the switch activation
        try {
            let Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/inventory-equipment/controller-inventory-equipment.php?option=Update_Status_Equipment_Inventory'; // URL for updating video data
            const Data = { // Prepare data object for update---JSON
                "Id_Equipment": Id_Components, "Active": Active
            };
            const response = await Send_Apis_request( // Send update request to server
                Url, // URL for updating video data
                Data, 'POST');
            if (!response.ok) { // Handling non-ok response
                throw new Error('Failed to update video'); // Throwing error
            }
            // Updating local state if server request was successful
            const updatedComponent = Components.map(Component => { // Mapping through libraries
                if (Component.Id_Equipment === Id_Components) { // Checking for matching Dashboard ID
                    return {...Component, Active: Active ? 1 : 0}; // Updating active status
                }
                return Component; // Returning an unchanged Dashboard if ID doesn't match
            });
            setComponents(updatedComponent); // Setting updated libraries state
        } catch (error) { // Catching errors
            console.error('Error updating switch state:', error); // Logging error
        }
    };

    // ******************Function for handling edit mode activation*******************************
    // ******************Función para manejar la activación del modo de edición*******************************
    const HandleEditActive = async (Id_Equipment,Components) => {

        const component = Components.find(component => component.Id_Equipment === Id_Equipment);

        await setGlobal_Cod_Equipment(component.Cod_Equipment); // Set global ID library state
        await setGlobal_Id_Asset_Type(component.Id_Asset_Type);
        await setGlobal_Equipment_Description(component.Equipment_Description);
        await setGlobal_Serial_Equipment(component.Serial_Equipment);
        await setGlobal_Id_Equipment_Type(component.Id_Equipment_Type);
        await setGlobal_Description_Asset_Type(component.Description_Asset_Type);
        await setGlobal_Description_Equipment_Type(component.Description_Equipment_Type);
        SetEditActive(!EditActive); // Toggle edit mode state
    }


    const HandleIdEquipmentChange = (e) => { // Function for handling description change

        setGlobal_Cod_Equipment(e.target.value); // Update global description video state
    };

    const HandleDescriptionChange = (e) => { // Function for handling description change

        setGlobal_Cod_Equipment(e.target.value); // Update global description video state
    };


    const HandleDateMovedChange = (e) => { // Function for handling description change


        setGlobal_Date_Moved(e.target.value); // Update global description video state

    };

    const HandleIdAssetTypeChange = (e) => { // Function for handling description change


        setGlobal_Id_Asset_Type(e.target.value); // Update global description video state

    };


    const HandleDescriptionAssetTypeChange = (e) => { // Function for handling description change


        setGlobal_Description_Asset_Type(e.target.value); // Update global description video state

    };

    const HandleIdJobOriginChange = (e) => { // Function for handling description change


        setGlobal_Serial_Equipment(e.target.value); // Update global description video state

    };


    const HandleEquipmentDescriptionChange = (e) => { // Function for handling description change


        setGlobal_Equipment_Description(e.target.value); // Update global description video state

    };


    const HandleDescriptionEquimpentTypeChange = (e) => { // Function for handling description change


        setGlobal_Description_Equipment_Type(e.target.value); // Update global description video state

    };

    const HandleIdJobDestinationChange = (e) => { // Function for handling description change


        setGlobal_Id_Equipment_Type(e.target.value); // Update global description video state

    };


    const HandleDescriptionEquipmentTypeChange = (e) => { // Function for handling description change


        setGlobal_Serial_Equipment(e.target.value); // Update global description video state

    };


    const HandleStartReportingOnChange = (e) => { // Function for handling description change


        setGlobal_Start_Reporting_On(e.target.value); // Update global description video state

    };


    const HandleToStopReportingOnChange = (e) => { // Function for handling description change


        setGlobal_To_Stop_Reporting_On(e.target.value); // Update global description video state

    };


    const HandleSendUpdateDash = async (Id_Component) => { // Function for handling video update submission
        let response;
        const Data = {
            "Id_Equipment_Inventory": Id_Component, //"Date_Moved": Description_Update_Ref.current.value,
            "Date_Moved": Date_Moved_Update_Ref.current.value,
            "Id_Asset_Type": Id_Asset_Type_Update_Ref.current.value,
            "Id_equipment": Cod_Equipment_Update_Ref.current.value,
            "Id_Job_Origin": Equipment_Description_Update_Ref.current.value,
            "Id_Job_Destination": Serial_Equipment_Update_Ref.current.value,
            "Start_Reporting_On": Id_Equipment_Type_Update_Ref.current.value,
            "To_Stop_Reporting_On": To_Stop_Reporting_On_Update_Ref.current.value
        };
        debugger;
        console.log(Data);
        try {
            let Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/inventory-equipment/controller-inventory-equipment.php?option=Update_Equipment_Inventory'; // URL for updating video data
            await HandleEditActive(); // Activate edit mode
            response = await Send_Apis_request(Url, // URL for updating video data
                Data, 'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al enviable la actualization del video:', error); // Log error if update fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }


    const HandleAddDashSubmit = async (event) => { // Function for handling video addition submission
        let response;
        event.preventDefault(); // Prevent default form submission behavior
        const Data = { // Prepare data object for addition
            "Cod_Equipment": Cod_Equipment_Add_Ref.current.value,
            "Id_Asset_Type": Id_Asset_Type_Add_Ref.current.value === 'Attachment' ? 1 : Id_Asset_Type_Add_Ref.current.value === 'Auxiliary' ? 2 : 3,
            "Equipment_Description": Equipment_Description_Add_Ref.current.value,
            "Serial_Equipment": Serial_Equipment_Add_Ref.current.value ,
            "Id_Equipment_Type": Id_Equipment_Type_Add_Ref.current.value,
        };
        console.log(Data);
        try {
            let Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/inventory-equipment/controller-inventory-equipment.php?option=Insert_Equipment_Inventory'; // URL for updating video data
            await HandleAddActive(); // Activate add mode
            response = await Send_Apis_request(Url, // URL for insert video data
                Data, 'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al agregar el video:', error); // Log error if addition fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }


    const HandleAddActive = () => { // Function to toggle add mode
        SetAddActive(!AddActive); // Toggle add mode state
    }

    const HandleSearchChange = (e) => { // Function to handle search input change
        setSearchParam(e.target.value); // Update search parameter state
    }

    const ClearDashboards = () => { // Function to clear Dashboards state
        setComponents([]); // Clear Dashboards state
    };


    let fetchData;
    fetchData = useCallback(async (Description_Dashboard) => {
        try {
            let response;
            let Url;
            //Fix the URL
            if (Description_Dashboard !== "") {
                Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/inventory-equipment/controller-inventory-equipment.php?option=Get_One_Equipment_Inventory'; // URL for fetching all categories
                const data = {
                    "Concat_Info_Equipment_Inventory": Description_Dashboard
                };
                response = await Send_Apis_request(Url, data, 'POST'); // Send request to server
            } else {
                Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/inventory-equipment/controller-inventory-equipment.php?option=Get_All_Equipment_Inventory'; // URL for fetching all categories
                response = await Send_Apis_request(Url, {}, 'GET'); // Send request to server
            }
            const data = await response.json();


            let componentsData;

            if (Array.isArray(data.items)) {
                componentsData = data.items;
            } else if (data.items) {
                componentsData = [data.items];
            } else {
                componentsData = [];
            }

            setComponents(componentsData);

            console.log(componentsData);

            // Check if there are more pages to fetch
            if (data.nextPageToken) {
                // Recursively fetch more data
                await fetchData(Description_Dashboard);
            }
        } catch (error) {
            console.error('Error fetching libraries:', error);
        }
    }, [fetchData]);

    //----------------------------USE EFFECT--------------------------------
    useEffect(() => {
        ClearDashboards();
        fetchData(searchParam);
    }, [searchParam, fetchData]);


    return (<div id="Navbar_Admin_Body">
        <NavbarLeftVirtualLibraryComponent/>
        <nav id="Navbar_Admin_Top">
            <button
                onClick={HandleAddActive}
                title="Add New"
                className="custom-button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60px"
                    height="60px"
                    viewBox="0 0 24 24"
                    className="custom-svg"
                >
                    <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        strokeWidth="1.5"
                    ></path>
                    <path d="M8 12H16" strokeWidth="1.5"></path>
                    <path d="M12 16V8" strokeWidth="1.5"></path>
                </svg>
            </button>
            <form id="Form_Navbar_Top_Admin_Search">
                <label id="Label_Navbar_Top_Admin_Search" htmlFor="search">
                    <input
                        id="Input_Navbar_Top_Admin_Search"
                        type="text"
                        required=""
                        placeholder="Search Equipment"
                        className="search"
                        value={searchParam}
                        onChange={HandleSearchChange}
                    />
                    <div id="Fancy_BG_Navbar_Top_Admin_Search"></div>
                    <div id="Search_Navbar_Top_Admin_Search">
                        <svg viewBox="0 0 24 24" aria-hidden="true" id="SVG_Navbar_Top_Admin_PowerBi_Search"
                             className=" r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
                            <g>
                                <path
                                    d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                            </g>
                        </svg>
                    </div>
                    <button className="close-btn" onClick={() => setSearchParam('')}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="SVG_Navbar_Top_Admin_Search"
                             className=" h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </button>
                </label>
            </form>


            <section id="Section_Navbar_Top_Admin_Widgets">
                <div id="Div_Navbar_Top_Admin_Widgets_Search">

                </div>
                <div id="Div_Navbar_Top_Admin_Widgets_Notifications">
                    <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                         className="bi bi-bell-fill" viewBox="0 0 16 16">
                        <path
                            d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
                    </svg>
                </div>
                <div id="Div_Navbar_Top_Admin_Widgets_Messages">

                    <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"
                         className="bi bi-chat-left-text-fill" viewBox="0 0 16 16">
                        <path
                            d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
                    </svg>
                </div>

            </section>
            <section id="Section_Navbar_Top_Admin_Profile">
                <div id="Div_Navbar_Top_Admin_Profile_Info">
                    <div id="Div_Navbar_Top_Admin_Profile_Name">
                        <p style={{
                            fontFamily: 'cursive, serif', fontSize: '1.5rem', padding: '0', margin: '0'
                        }}>{localStorage.getItem('Complete_Name')}</p>
                    </div>
                    <div id="Div_Navbar_Top_Admin_Profile_Type_Employee">
                        <p style={{fontFamily: 'inherit'}}>Admin</p>
                    </div>
                </div>
                {localStorage.getItem('Complete_Name') === "Stiven Mazo" ?
                    <div id="Div_Navbar_Top_Admin_Profile_Icon"
                         style={{background: `url(${IMG_Example_Profile_Stiven}) no-repeat center center / contain`}}></div> :
                    <div id="Div_Navbar_Top_Admin_Profile_Icon"
                         style={{background: `url(${IMG_Example_Profile_Ander}) no-repeat center center / contain`}}></div>}
            </section>
        </nav>
        <div id="Div_Content_Right_Admin_PowerBi">


            <div id="Div_Content_Right_Admin_PowerBi_Table">
                <table id="Table_Admin_PowerBi">
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Cod Equipment</th>
                        <th>Equipment Description</th>
                        <th>Description_Equipment_Type</th>
                        <th>Description_Asset_Type</th>
                        <th>Created_At</th>
                        <th>Updated_At</th>
                        <th>Status</th>
                        <th>Switch</th>
                        <th>Edit</th>
                    </tr>
                    {Components.map((Component, index) => (
                        <tr id="TR_Admin_PowerBi_Virtual_Library" key={`${index}-${Component.Id_Equipment}`}>
                            <td>{Component.Id_Equipment}</td>
                            <td>{Component.Cod_Equipment}</td>
                            <td>{Component.Equipment_Description}</td>
                            <td>{Component.Description_Equipment_Type}</td>
                            <td>{Component.Description_Asset_Type}</td>
                            <td>{Component.Created_At}</td>
                            <td>{Component.Updated_At}</td>
                            <td>
                                <div id="bauble_box" className="bauble_box">
                                    <input
                                        className="bauble_input"
                                        id="bauble_check1"
                                        name="bauble"
                                        type="checkbox"
                                        checked={Component.Active === 1}
                                        onChange={(e) => HandleActiveSwitch(Component.Id_Equipment, e.target.checked)}
                                    />
                                    <label id="bauble_label" className="bauble_label"
                                           htmlFor="bauble_check">Toggle</label>
                                </div>

                            </td>
                            <td>
                                <button id="EditBtn" onClick={() => {//
                                    HandleEditActive(Component.Id_Equipment,Components);
                                }}>
                                    <svg height="1em" viewBox="0 0 512 512">
                                        <path
                                            d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                        ></path>
                                    </svg>
                                </button>
                            </td>
                        </tr>))}
                    </thead>
                </table>
            </div>
        </div>


        {AddActive && <div id="Div_Add_Equipment">
            <section id="Section_Add_Equipment">
                <h1 id="H1_Section_Edit_Admin_PowerBi">Add Equipment Moves Log</h1>


                <form id="Form_Div_Add_Equipment" onSubmit={HandleAddDashSubmit}>

                    <div id="Div_General_Form_Add_Equipment">
                        <div>
                            <label className="label-edit-admin" htmlFor="Cod Equipment">Cod Equipment</label>
                            <input
                                ref={Cod_Equipment_Add_Ref}
                                type="text"
                                className="input-edit-admin"
                                id="Cod Equipment"
                                name="Cod Equipment"
                                placeholder="7100"
                            />
                        </div>
                        <div>
                            <label className="label-edit-admin" htmlFor="typeOfAsset">Type of Asset:</label>
                            <select ref={Id_Asset_Type_Add_Ref} className="input-edit-admin" id="typeOfAsset"
                                    name="typeOfAsset">
                                <option value="">Select a type</option>
                                <option value="Attachment">Attachment</option>
                                <option value="Auxiliary">Auxiliary</option>
                                <option value="Equipment">Equipment</option>
                            </select>
                        </div>


                        <div>
                            <label className="label-edit-admin" htmlFor="Equipment Description">Equipment
                                Description:</label>
                            <input
                                ref={Equipment_Description_Add_Ref}
                                className="input-edit-admin"
                                id="Equipment Description"
                                name="Equipment Description"
                                type="text"
                                placeholder="Caterpillar 725 C2"
                            />
                        </div>

                        <div>
                            <label className="label-edit-admin" htmlFor="serialEquipment">Serial Equipment:</label>
                            <input
                                ref={Serial_Equipment_Add_Ref}
                                type="text"
                                className="input-edit-admin"
                                id="serialEquipment"
                                name="serialEquipment"
                                placeholder="2T300368"
                            />
                        </div>

                        <div>
                            <label className="label-edit-admin" htmlFor="IdTypeEquipment">Id type of equipment:</label>


                                <select ref={Id_Equipment_Type_Add_Ref} className="input-edit-admin" id="typeOfAsset"
                                        name="typeOfAsset">

                                <option value="">Select a equipment type</option>
                                <option value="1">Broom attachment</option>
                                <option value="2">Cold Planer</option>
                                <option value="3">Equipment</option>
                                <option value="4">Hammer</option>
                                <option value="5">Harvester Head</option>
                                <option value="6">Power Tilt Couplert</option>
                                <option value="7">Skidsteer Mulcher</option>
                                <option value="8">Soil Plow</option>
                                <option value="9">Soil Rake</option>
                                <option value="10">Air Compressor</option>
                                <option value="11">Arrow board</option>
                                <option value="12">Fusion Machine</option>
                                <option value="13">Hydroseeding Unit</option>
                                <option value="14">Light tower</option>
                                <option value="15">Magnet Board</option>
                                <option value="16">Trench Roller</option>
                                <option value="17">Utility Task Vehicle</option>
                                <option value="18">Vacuum trailer</option>
                                <option value="19">Vacuum Truck</option>
                                <option value="20">Walk behind saw</option>
                                </select>

                        </div>
                        <section id="Div_Form_Buttons_Add_Equipment">
                            <button id="Button_Submit_Add_Equipment" type="submit">Submit</button>
                            <button id="Button_Cancel_Add_Equipment" type="button"
                                    onClick={HandleAddActive}>Cancel
                            </button>
                        </section>

                    </div>
                </form>
            </section>
        </div>}


        {EditActive && (<div id="Div_Add_Equipment">
            <section id="Section_Add_Equipment">
                <h1 id="H1_Section_Edit_Admin_PowerBi">Edit Equipment </h1>
                <form id="Form_Div_Add_Equipment" onSubmit={(e) => {
                    e.preventDefault();
                    HandleSendUpdateDash(Global_Cod_Equipment);
                }}>
                    <div id="Div_General_Form_Add_Equipment">
                        <div>
                            <label className="label-edit-admin" htmlFor="description">Cod Equipment:</label>
                            <input
                                ref={Cod_Equipment_Update_Ref}
                                className="input-edit-admin"
                                type="text"
                                id="description"
                                name="description"
                                placeholder="Equipment #"
                                value={Global_Cod_Equipment}
                                onChange={HandleIdEquipmentChange}
                            />
                        </div>

                        <div>
                            <label className="label-edit-admin" htmlFor="typeOfAsset">Type of Asset:</label>
                            <select
                             ref={Id_Asset_Type_Update_Ref}
                             className="input-edit-admin"
                             id="typeOfAsset"
                             name="typeOfAsset"
                             onChange={HandleIdAssetTypeChange}>
                                <option value={Global_Id_Asset_Type}>{Global_Description_Asset_Type}</option>
                                <option value="1">Attachment</option>
                                <option value="2">Auxiliary</option>
                                <option value="3">Equipment</option>

                            </select>


                        </div>
                        <div>
                            <label className="label-edit-admin" htmlFor="Equipment Description">Equipment
                                Description:</label>
                            <input
                                ref={Equipment_Description_Update_Ref}
                                className="input-edit-admin"
                                type="text"
                                id="Equipment Description"
                                name="Equipment Description"
                                placeholder="Caterpillar 725 C2"
                                value={Global_Equipment_Description}
                                onChange={setGlobal_Equipment_Description}
                            />
                        </div>

                        <div>
                            <label className="label-edit-admin" htmlFor="Serial Equipment">Serial Equipment:</label>
                            <input
                                ref={Serial_Equipment_Update_Ref}
                                className="input-edit-admin"
                                type="text"
                                id="Serial Equipment"
                                name="Serial Equipment"
                                placeholder="2T300368"
                                value={Global_Serial_Equipment}
                                onChange={HandleIdJobDestinationChange}
                            />
                        </div>

                        <div>
                            <label className="label-edit-admin" htmlFor="Id Equipment Type">Equipment Type:</label>
                            <input
                                ref={Id_Equipment_Type_Update_Ref}
                                className="input-edit-admin"
                                type="text"
                                id="Id Equipment Type"
                                name="Id Equipment Type"
                                placeholder="1"
                                value={Global_Description_Equipment_Type}
                                onChange={HandleDescriptionEquimpentTypeChange}
                            />
                        </div>

                        <section id="Div_Form_Buttons_Add_Equipment">
                            <button id="Button_Submit_Add_Equipment" type="submit">Submit</button>
                            <button id="Button_Cancel_Add_Equipment" type="button"
                                    onClick={HandleEditActive}>Cancel
                            </button>
                        </section>
                    </div>
                </form>
            </section>
        </div>)}
    </div>);
}
